export enum PolicyType {
    PRE_PROCESS = 'PRE_PROCESS',
    AUTO_TAG = 'AUTO_TAG',
    QUEUE_FILTER = 'QUEUE_FILTER',
    FRESHNESS_FILTER = 'FRESHNESS_FILTER',
    INTENT = 'INTENT',
    POST_PROCESS = 'POST_PROCESS',
    CRON = 'CRON',
    ROUTING = 'ROUTING',
}

export interface IPolicySet {
    id: number;
    category: string;
    name: string;
    policy_type: PolicyType;
    suggested: boolean;
    weight?: number;
    created_date: string;
    updated_at: string;
}

export interface IPolicySetGroup {
    category: string;
    intents: IPolicySet[];
}
