import { memo, useCallback, useState } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Nullable } from '@global/types';
import { IArgumentDateTime } from '@tymely/atoms';
import { offsetTimeZone } from '@tymely/utils';
import { TextFieldProps } from '@mui/material';

import { ArgumentFieldProps, StyledOutlinedInput } from './Layout';

export const DateTimeArgument = memo((props: ArgumentFieldProps<IArgumentDateTime>) => {
    const date = props.argument.value ? offsetTimeZone(new Date(props.argument.value)) : null;
    const edited = props.argument.is_edited;
    const [open, setOpen] = useState(false);

    const onUpdate = useCallback(
        (newDate: Nullable<Date>) => {
            if (!newDate) return;
            const isoDate = offsetTimeZone(newDate, false).toISOString();
            props.onChange?.([{ ...props.argument, value: isoDate }]);
        },
        [props.onChange, props.argument],
    );

    return (
        <DateTimePicker
            label={props.withLabel ? props.argument.name : ''}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            sx={{ width: '100%' }}
            slots={{
                textField: StyledOutlinedInput,
            }}
            slotProps={{
                textField: {
                    edited,
                    multiline: false,
                    onClick: () => setOpen(true),
                } as TextFieldProps,
            }}
            value={date}
            onChange={onUpdate}
            closeOnSelect
            disabled={props.disabled}
        />
    );
});
