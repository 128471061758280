import { Box, BoxProps, styled } from '@mui/material';
import { IComment, ITicketTrailEvent } from '@tymely/atoms';
import React from 'react';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import throttle from 'lodash/throttle';
import { VideoModal } from '@tymely/components';

import CommentGroup, { DiscussionProps as _DiscussionProps } from './CommentGroup';
import { formatGroupDate, getItemDate } from './utils';

const _Root = styled(Box, { shouldForwardProp: (prop: string) => !['loading'].includes(prop) })<{ loading?: boolean }>`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    position: relative;
    padding-right: ${({ theme }) => theme.spacing(1)};
    margin: ${({ theme }) => theme.spacing(0, 4)};
    scroll-behavior: ${({ loading }) => (loading ? 'auto' : 'smooth')};
    ::-webkit-scrollbar {
        width: 8px;
        height: 0;
    }
    ::-webkit-scrollbar-track-piece {
        background: ${({ theme }) => theme.palette.divider};
        border-color: ${({ theme }) => theme.palette.background.main};
        border-width: 1px 3px 1px 3px;
        border-style: solid;
    }
    ::-webkit-scrollbar-thumb:vertical {
        background: #66648e;
        border-radius: 6px;
    }
`;

const _Filler = styled('div')`
    flex: 1;
`;

type CommentsRef = {
    scrollToBottom: () => void;
    scrollToTop: () => void;
};

type DiscussionProps = BoxProps &
    _DiscussionProps & {
        comments: { date: string; comments: IComment[] }[];
        loading?: boolean;
        onScrollToTop?: (isTop: boolean) => void;
        onScrollToBottom?: (isBottom: boolean) => void;
    };

const Discussion = React.forwardRef<CommentsRef, DiscussionProps>(
    (
        {
            ticket,
            comments,
            commentIdToFocusOn,
            selectedCommentId,
            loading,
            onSelectComment,
            onUntag,
            highlightText,
            onDeselectComment,
            events,
            onScrollToBottom,
            onScrollToTop,
            ...rest
        },
        apiRef,
    ) => {
        const combinedArray: (ITicketTrailEvent | IComment)[] = React.useMemo(
            () => [...(events?.ticketEvents ?? []), ...comments.flatMap((commentGroup) => commentGroup.comments)],
            [comments, events?.ticketEvents],
        );

        const lastEvent = React.useMemo(() => {
            if (events?.ticketEvents) {
                return events.ticketEvents[events.ticketEvents.length - 1];
            }
            return undefined;
        }, [events?.ticketEvents]);

        const allItems: (ITicketTrailEvent | IComment)[] = sortBy(combinedArray, (item) => getItemDate(item));

        const groups = React.useMemo(() => {
            return Object.entries(groupBy(allItems, (item) => formatGroupDate(getItemDate(item))));
        }, [allItems]);

        React.useEffect(() => {
            const scrollToCommentId = selectedCommentId || commentIdToFocusOn;
            if (scrollToCommentId) {
                setTimeout(() => {
                    document
                        .getElementById(String(scrollToCommentId))
                        ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }, 0);
            }
        }, [selectedCommentId, commentIdToFocusOn]);

        React.useEffect(() => {
            setTimeout(() => {
                document.getElementById('events-loading')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 100);
        }, [events]);

        const [videoUrl, setVideoUrl] = React.useState<string | undefined>(undefined);
        const onLinks = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
            e.preventDefault();
            const link = e.target as HTMLAnchorElement;
            if (link?.href?.endsWith('.mp4')) {
                setVideoUrl(link.href);
            }
        }, []);

        const ref = React.useRef<HTMLElement>();
        const scrollTopRef = React.useRef(0);
        React.useImperativeHandle(apiRef, () => ({
            scrollToBottom: () => {
                ref.current?.scrollTo({ top: ref.current.scrollHeight, behavior: 'smooth' });
            },
            scrollToTop: () => {
                ref.current?.scrollTo({ top: -ref.current.scrollHeight, behavior: 'smooth' });
            },
        }));

        const onScroll = React.useCallback(
            throttle(
                (event: React.SyntheticEvent<HTMLDivElement>) => {
                    if (!event.currentTarget) return;
                    const scrollTop = Math.abs(event.currentTarget.scrollTop);
                    const scrollHeight = Math.abs(event.currentTarget.scrollHeight);
                    const clientHeight = Math.abs(event.currentTarget.clientHeight);
                    scrollTopRef.current = scrollTop;
                    const isBottom = scrollTop + clientHeight >= scrollHeight - 100;
                    onScrollToBottom?.(isBottom);
                    const isTop = scrollTop <= 100;

                    onScrollToTop?.(isTop);
                },
                10,
                { trailing: true },
            ),
            [scrollTopRef.current, allItems],
        );

        return (
            <_Root {...rest} component="div" ref={ref} loading={loading} onScroll={onScroll} onClick={onLinks}>
                <_Filler />
                {groups.map(([date, items]) => (
                    <CommentGroup
                        key={date}
                        date={date}
                        lastEvent={lastEvent}
                        events={events}
                        items={items}
                        highlightText={highlightText}
                        ticket={ticket}
                        selectedCommentId={selectedCommentId}
                        commentIdToFocusOn={commentIdToFocusOn}
                        onSelectComment={onSelectComment}
                        onDeselectComment={onDeselectComment}
                        onUntag={onUntag}
                    />
                ))}
                {videoUrl && <VideoModal open onClose={() => setVideoUrl(undefined)} url={videoUrl} />}
            </_Root>
        );
    },
);

export default Discussion;
