import React from 'react';
import { IComment } from '@tymely/atoms';
import { useFindIntent, useCommentIdToFocusOn } from '@tymely/services';

import CommentHeader from './CommentHeader';
import CommentBody from './CommentBody';
import { CommentContainer, CommentContent } from './styled';

import 'react-photo-view/dist/react-photo-view.css';

const DemoBody = React.lazy(() => import('./DemoBody'));

export interface CommentProps {
    comment: IComment;
    subject?: string;
    username?: string;
    disableUntag?: boolean;
    onClick?: (comment: IComment) => void;
    toggleIsCustomer?: (comment: IComment) => void;
    onUntag?: (comment: IComment) => void;
}

const Comment = React.memo(({ comment, subject, username, onClick, toggleIsCustomer }: CommentProps) => {
    const commentRef = React.createRef<HTMLLIElement>();
    const commentIdToFocusOn = useCommentIdToFocusOn();
    const intent = useFindIntent(comment?.selected_intent_id);

    React.useEffect(() => {
        comment.id === commentIdToFocusOn && commentRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [commentRef.current]);

    const onCommentClick = () => {
        onClick?.(comment);
    };

    // Demo
    const needHighlight = Boolean(comment.additional_data?.['highlights']);

    const bodyElem = needHighlight ? (
        <React.Suspense>
            <DemoBody comment={comment} subject={subject} />
        </React.Suspense>
    ) : (
        <CommentBody comment={comment} subject={subject} />
    );

    return (
        <CommentContainer isCustomer={comment.is_customer} ref={commentRef}>
            <CommentContent
                primary={
                    <CommentHeader
                        comment={comment}
                        username={username}
                        intentName={intent?.name}
                        toggleIsCustomer={toggleIsCustomer}
                        isInternal={comment.is_public === false}
                    />
                }
                primaryTypographyProps={{ component: 'div' }}
                secondaryTypographyProps={{ component: 'div' }}
                secondary={bodyElem}
                isInternal={comment.is_public === false}
                isCustomer={comment.is_customer}
                hasIntent={Boolean(intent)}
                isSelected={commentIdToFocusOn === comment.id}
                onClick={onCommentClick}
            />
        </CommentContainer>
    );
});

export default Comment;
