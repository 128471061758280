import { memo, ReactNode, ElementType, useRef, useCallback, useState } from 'react';
import { Typography, Box, experimentalStyled as styled, BoxProps } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import MenuBase, { menuClasses } from '@mui/base/Menu';
import MenuItem, { menuItemClasses, MenuItemProps } from '@mui/base/MenuItem';
import Popper, { PopperProps, PopperPlacementType } from '@mui/base/Popper';
import Button from '@mui/base/Button';
import { typedMemo } from '@global/types';

export type MenuOption<T> = {
    value: T;
    label: string;
    view?: ReactNode;
};

export type GroupedMenuOption<T> = MenuOption<T> & {
    group: {
        id: number | string;
        label: string;
    };
};

const StyledMenuItem = styled(MenuItem)<MenuItemProps>(
    ({ theme, selected }) => `
    display: flex;
    align-items: center;
    list-style: none;
    padding: ${theme.spacing(1, 3)};
    border-radius: ${theme.spacing(3)};
    cursor: pointer;
    user-select: none;
    color: ${selected ? theme.palette.common.white : theme.nav.text};
    background-color: ${selected ? theme.palette.common.selected : 'transparent'};
    text-transform: capitalize;

    &.${menuItemClasses.focusVisible}, &:hover {
        outline: none;
        color: ${theme.palette.common.white};
    }
    `,
);

export const StyledListbox = styled('ul')(
    () => `
    margin: 0;
    min-width: fit-content;
    padding: 0;
    height: 100%;
    overflow: auto;
    outline: 0;
    `,
);

export const NavPopper = styled((props: PopperProps) => (
    <Popper
        {...props}
        placement={props.placement || 'right-start'}
        modifiers={[
            {
                name: 'flip',
                options: {
                    fallbackPlacements: [],
                },
            },
        ]}
    />
))(
    ({ theme }) => `
    max-height: 30vh;
    padding: ${theme.spacing(2)};
    padding-right: ${theme.spacing(3)};
    border-radius: ${theme.shape.borderRadiusLarge}px;
    background: ${theme.nav.default};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    &.${menuClasses.expanded} {
        background: ${theme.nav.active};
    }
    z-index: ${theme.zIndex.modal};
`,
);

export const Menu = memo(
    (props: {
        open: boolean;
        anchorEl: HTMLElement | null;
        onClose?: () => void;
        popper?: ElementType;
        container?: ElementType;
        children: ReactNode;
        placement?: PopperPlacementType;
        disablePortal?: boolean;
        className?: string;
        popperProps?: object;
    }) => {
        return (
            <MenuBase
                open={props.open}
                anchorEl={props.anchorEl}
                slots={{ root: props.popper || NavPopper, listbox: props.container || StyledListbox }}
                onOpenChange={props.onClose}
                slotProps={{
                    root: { placement: props.placement, disablePortal: props.disablePortal, ...props.popperProps },
                }}
                className={props.className}
            >
                {props.children}
            </MenuBase>
        );
    },
);

type NavOption<T> = MenuOption<T> & { color?: string };

const OptionColor = styled(Box)<{ color?: string }>(
    ({ color, theme }) => `
    border-radius: 50%;
    width: ${theme.spacing(1.5)};
    height: ${theme.spacing(1.5)};
    background-color: ${color};
    margin-left: auto;
`,
);

export const NavMenu = typedMemo(
    <T extends string | number>(props: {
        open: boolean;
        anchorEl: HTMLElement | null;
        value?: T;
        options: NavOption<T>[];
        onSelect: (value: T) => void;
        onClose?: () => void;
        placement?: PopperPlacementType;
        popper?: ElementType;
        disablePortal?: boolean;
        className?: string;
    }) => {
        return (
            <Menu
                open={props.open}
                anchorEl={props.anchorEl}
                onClose={props.onClose}
                placement={props.placement}
                popper={props.popper}
                disablePortal={props.disablePortal}
                className={props.className}
            >
                {props.options.map((opt) => (
                    <StyledMenuItem
                        key={opt.value}
                        selected={opt.value === props.value}
                        onClick={(event) => {
                            event.preventDefault();
                            props.onSelect?.(opt.value);
                        }}
                    >
                        {opt.label}
                        {opt.color && <OptionColor color={opt.color} />}
                    </StyledMenuItem>
                ))}
            </Menu>
        );
    },
);

const SelectButton = styled(Button)(
    ({ theme }) => `
    display: flex;
    align-items: center;
    padding: ${theme.spacing(0, 3)};
    margin: 0;
    height: 100%;
    background: inherit;
    border: 0;
    cursor: pointer;
    outline: none;
    color: ${theme.palette.common.white};
`,
);

const SelectPopper = styled(NavPopper)(
    ({ theme }) => `
    border-bottom-left-radius: ${theme.shape.borderRadiusLarge}px;
    border-top-right-radius: 0;
    width: 65%;
    height: 30vh;
    padding-top: ${theme.spacing(6)};
    top: ${theme.spacing(-4)} !important;
    &.${menuClasses.expanded} {
        background: inherit;
    }
    z-index: -1;
`,
);

const SelectContainer = styled(Box)(
    () => `
    height: 100%;
    background: #3E3D66;
`,
);

export const ArrawIcon = styled((props: BoxProps) => (
    <Box {...props} component="span">
        <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L5 4L9 1" stroke="currentColor" strokeWidth="1.47422" strokeLinecap="round" />
        </svg>
    </Box>
))<{ open: boolean }>(
    ({ open }) => `
    svg {
        transform: ${open ? 'rotate(180deg)' : 'rotate(0deg)'};
        vertical-align: middle;
    }
`,
);

export const NavSelect = <T extends number | string>(props: {
    value?: T;
    options?: NavOption<T>[];
    onSelect: (value: T) => void;
    onClose?: () => void;
    sx?: SxProps<Theme>;
}) => {
    const selectRef = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState(false);
    const onToggle = useCallback(() => {
        if (!props.options) return;
        setOpen((open) => !open);
    }, [open, props.options]);
    const onSelect = useCallback(
        (value: T) => {
            props.onSelect(value);
            setOpen(false);
            selectRef.current?.focus();
        },
        [props.onSelect],
    );
    return (
        <SelectContainer sx={props.sx}>
            <SelectButton ref={selectRef} onClick={onToggle}>
                <Typography
                    component="div"
                    sx={{ width: 108, pr: 3, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                >
                    {props.options?.find(({ value }) => value === props.value)?.label || 'Org.'}
                </Typography>
                <ArrawIcon open={open} color="common.white" />
            </SelectButton>
            <NavMenu
                anchorEl={selectRef.current}
                open={Boolean(props.options && open)}
                placement="bottom-start"
                value={props.value}
                popper={SelectPopper}
                options={props.options || []}
                onSelect={onSelect}
                onClose={props.onClose}
                disablePortal
            />
        </SelectContainer>
    );
};
