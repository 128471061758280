import axios from 'axios';
import { IArgument, IComment, IDecision, ITicket, normalizeDtype } from '@tymely/atoms';
import { Nullable } from '@global/types';

export enum AppMode {
    Online = 'online',
    Labeling = 'labeling',
    Analyst = 'analyst',
    Admin = 'admin',
    Training = 'training',
    QA = 'qa',
}

export const AppModes = Object.fromEntries(Object.entries(AppMode).map(([label, value]) => [value, label])); // online => Online

export const postEvaluatePolicy = (commentId: number, overrideArguments?: IArgument[], async = false) =>
    axios
        .post<IComment>(`comment/${commentId}/evaluate-policy`, overrideArguments, { params: { async } })
        .then((resp) => resp.data);

export const updateComment = (commentId: number, value: IComment) => axios.put<IComment>(`comment/${commentId}`, value);

export const getComment = (commentId: number) => axios.get<IComment>(`comment/${commentId}`).then((res) => res.data);

export const getCommentArguments = (commentId: number, version?: string) => {
    return axios
        .get<IArgument[]>(`comment/${commentId}/arguments`, {
            params: {
                as_seen_at: version,
            },
        })
        .then((res) => res.data)
        .then((args) =>
            args.map((arg) => {
                arg.dtype = normalizeDtype(arg.dtype);
                return arg;
            }),
        );
};

export const getCommentArgumentVersions = (comment_id: number) =>
    axios.get<string[]>(`comment/${comment_id}/arguments/versions`).then((res) => res.data);

export const getCommentDecision = (commentId: number) =>
    axios.get<Nullable<IDecision>>(`comment/${commentId}/decision`).then((res) => res.data);

export const tagComment = async (commentId: number, intentIds: number[], mode: AppMode, evaluatePolicy = false) =>
    axios
        .post<IComment>(
            `comment/${commentId}/tag?${intentIds
                .map((id) => `policy_set_id=${id}`)
                .join('&')}&mode=${mode}&evaluate_policy=${evaluatePolicy}`,
        )
        .then((resp) => resp.data);

export const untagComment = async (commentId: number) =>
    axios.post<IComment>(`comment/${commentId}/untag`).then((resp) => resp.data);

export const postSubmitByCommentId = (
    commentId: number,
    dryMode: boolean,
    appMode: AppMode,
    responseBody?: Nullable<string>,
    afterHandling = true,
) =>
    axios
        .post<ITicket>(
            `comment/${commentId}/submit`,
            {
                response_body: responseBody,
            },
            {
                params: {
                    dry_mode: dryMode,
                    mode: appMode,
                    after_handling: afterHandling,
                },
            },
        )
        .then((resp) => resp.data);

export const afterTicketHandling = (commentId: number) =>
    axios.post<IComment>(`comment/${commentId}/after_handling`).then((res) => res.data);

export const resetComment = (commentId: number) => axios.put<void>(`comment/${commentId}/reset`);
