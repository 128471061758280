import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UseMutationOptions, UseQueryOptions } from 'react-query/types/react/types';
import {
    fetchResponseTemplate,
    getTemplateFreeVariables,
    renderTemplate,
    editResponseTemplate,
    createResponseTemplate,
    TemplateError,
    ITemplateVariable,
    IResponseTemplate,
} from '@tymely/api';

import { useSetAlert } from './alerts.services';

export const useFetchResponseQuery = (
    templateId?: number,
    options?: UseQueryOptions<IResponseTemplate | null, TemplateError>,
) => {
    const setAlert = useSetAlert();
    return useQuery<IResponseTemplate | null, TemplateError>(
        ['response-template', templateId],
        () => (templateId ? fetchResponseTemplate(templateId) : Promise.resolve(null)),
        {
            onError: () => {
                setAlert(`Failed to load template ${templateId}`, 'error');
            },
            enabled: Boolean(templateId),
            ...options,
        },
    );
};

export const useRenderTemplateQuery = (
    template: string,
    variables: Record<string, ITemplateVariable>,
    sanitize?: boolean,
    options?: UseQueryOptions<string>,
) =>
    useQuery<string>(
        ['rendered-template', template, variables, sanitize],
        () => renderTemplate(template, variables, sanitize),
        {
            ...options,
            retry: false,
        },
    );

export const useTemplateFreeVariablesQuery = (template: string, options?: UseQueryOptions<string[]>) =>
    useQuery<string[]>(['free-vars', template], () => getTemplateFreeVariables(template), {
        ...options,
        retry: false,
    });

export const useEditResponseTemplateMutation = (
    options?: UseMutationOptions<IResponseTemplate, AxiosError | TemplateError, IResponseTemplate>,
) => {
    const queryClient = useQueryClient();

    return useMutation<IResponseTemplate, AxiosError | TemplateError, IResponseTemplate>(editResponseTemplate, {
        onSuccess: (data) => {
            queryClient.setQueriesData(['response-template', data.id], () => data);
        },
        ...options,
    });
};

export const useCreateResponseTemplateMutation = () => {
    const queryClient = useQueryClient();

    return useMutation<IResponseTemplate, AxiosError, Omit<IResponseTemplate, 'id'>>(createResponseTemplate, {
        onSuccess: (data) => {
            queryClient.setQueriesData(['response-template', data.id], () => data);
        },
    });
};
