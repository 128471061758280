import isEqual from 'lodash/isEqual';
import groupBy from 'lodash/groupBy';
import { useCallback, useMemo, useState, useLayoutEffect, useRef } from 'react';
import { FormControl, InputLabel } from '@mui/material';
import { IArgumentMultiCategory, ICategoryItem, IArgumentCategory, isMultiCategorical } from '@tymely/atoms';
import { Option } from '@tymely/components';
import { Nullable, typedMemo } from '@global/types';

import { ArgumentFieldProps, StyledMultiSelect } from './Layout';

export const isCategoricalArgChanged = (
    arg: IArgumentCategory | IArgumentMultiCategory<string | ICategoryItem>,
    value: typeof arg.value,
) => {
    // Skip not is_edited since it can be changed from null unedited to null edited.
    return !arg.is_edited || !isEqual(new Set(arg.value), new Set(value)) || (arg.is_unspecified && value === null);
};

export const getItems = (categories: Record<string, string | ICategoryItem>, groupByLabel?: boolean) => {
    const options = Object.entries(categories).map(
        ([key, value]) =>
            ({
                value: [key],
                name: typeof value === 'string' ? value : value.title || value.name,
            }) as Option<string[]>,
    );
    // If groupProp is provided, all options with the same name are grouped
    // into one option as { value: [val1, val2, ....], name }.
    return groupByLabel
        ? Object.entries(groupBy(options, 'name')).map(([name, value]) => {
              return { name, value: value.map((item) => item.value).flat() };
          })
        : options;
};

export const MultiCategoryArgument = typedMemo(function MultiCategoryArgument<
    T extends IArgumentCategory | IArgumentMultiCategory<string> | IArgumentMultiCategory<ICategoryItem>,
>(props: Omit<ArgumentFieldProps<T>, 'onChange'> & { onChange: (arg: T[]) => Promise<T[]> }) {
    const [initValue, setInitValue] = useState<typeof props.argument.value>(props.argument.value);
    const [argValue, setArgValue] = useState<typeof props.argument.value>(props.argument.value);

    if (isMultiCategorical(props.argument) && props.argument.value && !Array.isArray(props.argument.value)) {
        throw new Error(`multi select categorical argument expects value as array not ${typeof props.argument.value}`);
    }

    const getTypedValue = useCallback(
        (raw: string): any => {
            if (['int', 'float', 'list[int]', 'list[float]'].includes(props.argument.dtype)) {
                return Number(raw);
            }
            return raw;
        },
        [props.argument.dtype],
    );

    useLayoutEffect(() => {
        setInitValue(props.argument.value);
        setArgValue(props.argument.value);
    }, [props.argument.value]);

    const options = useMemo(() => {
        if (!props.argument.categories) return [];
        return getItems(props.argument.categories, props.argument.group_by_label);
    }, [props.argument.categories]);

    const selectedArguments = useMemo(
        () =>
            options
                ? options.filter((item) =>
                      item.value.every((raw) => {
                          const value = getTypedValue(raw);
                          return Array.isArray(argValue) ? argValue.includes(value) : value === argValue;
                      }),
                  )
                : [],
        [options, argValue],
    );

    const onChange = useCallback(
        (argValue: typeof props.argument.value) => {
            if (!isCategoricalArgChanged(props.argument, argValue)) return;
            props.onChange?.([{ ...props.argument, value: argValue }]).catch(() => {
                // Revert to initial on failure.
                setArgValue(initValue);
            });
            optionSelected.current = false;
        },
        [props.argument, initValue, props.onChange],
    );

    // TYM-1945: used to group multiple items with similar label, arg value is a list.
    const isGrouped = props.argument.group_by_label;
    const isList = props.argument.is_list;
    const multiple = !isGrouped && isList;
    const optionSelected = useRef<boolean>(false);
    const onSelectOptions = useCallback(
        (selected: Nullable<Option<string[]>[]>) => {
            const selectedValues =
                selected &&
                selected
                    .map((item) => item.value)
                    .flat()
                    .map(getTypedValue);
            const value = isList ? selectedValues : selectedValues && selectedValues.length ? selectedValues[0] : null;

            setArgValue(value);
            // If it's not multiple, the dropdown will close.
            if (selected === null || !multiple) {
                onChange(value);
            } else {
                optionSelected.current = true;
            }
        },
        [isList, multiple, onChange],
    );

    const onClose = useCallback(() => optionSelected.current && onChange(argValue), [argValue, onChange]);

    return (
        <FormControl size="small" fullWidth>
            {props.withLabel && <InputLabel>{props.argument.name}</InputLabel>}
            <StyledMultiSelect
                id={`${props.argument.extractor_cls_name.toLowerCase()}-select`}
                options={options || []}
                multiple={multiple}
                neitherable={props.argument.neitherable}
                edited={props.argument.is_edited}
                unspecified={props.argument.is_unspecified}
                neither={Boolean(options.length && !props.argument.value?.length && props.argument.is_edited)}
                value={selectedArguments}
                loading={props.loading}
                disabled={props.disabled}
                label={props.withLabel ? props.argument.name : ''}
                onOptsSelect={onSelectOptions}
                onClose={onClose}
            />
        </FormControl>
    );
});
