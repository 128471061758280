export default () => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.5 2.375V2.375C13.4354 2.375 16.625 5.56462 16.625 9.5V9.5C16.625 13.4354 13.4354 16.625 9.5 16.625V16.625C5.56462 16.625 2.375 13.4354 2.375 9.5V9.5C2.375 5.56462 5.56462 2.375 9.5 2.375Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.49886 9.89583V5.9375"
                stroke="#FE7CA3"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.49807 12.6667C9.38882 12.6667 9.30015 12.7553 9.30095 12.8646C9.30095 12.9738 9.38961 13.0625 9.49886 13.0625C9.60811 13.0625 9.69678 12.9738 9.69678 12.8646C9.69678 12.7553 9.60811 12.6667 9.49807 12.6667"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
