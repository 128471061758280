import { Box, Chip, styled, Switch } from '@mui/material';
import { IComment } from '@tymely/atoms';
import { useResetComment } from '@tymely/services';
import React from 'react';
import { useIsMutating } from 'react-query';

const _Root = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: theme.spacing(3);
`;

const _SelectedIntent = styled(Chip)`
    color: ${({ theme }) => theme.palette.common.white};
    position: absolute;
    height: ${({ theme }) => theme.spacing(2.5)};
    top: ${({ theme }) => theme.spacing(-2.5)};
    left: 0;
    background-color: ${({ theme }) => theme.palette.grey[600]};
    text-transform: lowercase;
    span::first-letter {
        text-transform: uppercase;
    }
    &.MuiSvgIcon-root {
        color: ${({ theme }) => theme.palette.common.white};
    }
`;

const _Sender = styled(Box)`
    white-space: nowrap;
`;

const _IntentContainer = styled(Box)`
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
`;

type CommentHeaderProps = {
    comment: IComment;
    username?: string;
    intentName?: string;
    toggleIsCustomer?: (c: IComment) => void;
    isInternal: boolean;
};

const CommentHeader = React.memo(
    ({ comment, toggleIsCustomer, intentName, username, isInternal }: CommentHeaderProps) => {
        const { mutateAsync: resetComment } = useResetComment(comment);
        const isResettingComment = useIsMutating({ mutationKey: ['resetComment', comment.id] }) > 0;

        const onToggleIsCustomer = React.useCallback(() => {
            toggleIsCustomer?.(comment);
        }, [toggleIsCustomer, comment]);

        return (
            <_Root>
                <_Sender>{comment.from_name === username ? 'Me' : comment.from_name}</_Sender>
                <_IntentContainer>
                    {intentName && (
                        <_SelectedIntent
                            id={`selected-intent-chip-for-comment-${comment.id}`}
                            label={intentName}
                            size="small"
                            onDelete={resetComment}
                            disabled={isResettingComment}
                        />
                    )}
                    <Switch
                        color={isInternal ? 'warning' : 'success'}
                        size="small"
                        checked={!comment.is_customer}
                        onChange={onToggleIsCustomer}
                        onClick={(event) => event.stopPropagation()}
                    />
                </_IntentContainer>
            </_Root>
        );
    },
);

export default CommentHeader;
