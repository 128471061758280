import { ForwardedRef, forwardRef, Fragment, memo, useCallback, useEffect, useState } from 'react';
import {
    Box,
    Collapse,
    collapseClasses,
    List as ListBase,
    ListItem as ListItemBase,
    ListItemAvatar,
    ListItemButton,
    ListItemButtonProps,
    listItemClasses,
    ListItemText,
    ListSubheader,
    ListSubheaderProps,
    styled,
    SxProps,
    Tooltip,
    Typography,
    TypographyProps,
    useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import MergeIcon from '@mui/icons-material/Merge';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import { addDays, format, isToday } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { IComment, ITicket, TicketHistoryInfo } from '@tymely/atoms';
import { IEvent, useSubscription, useTicket, useTicketHistory, useTicketOrganization } from '@tymely/services';
import { Avatar } from '@tymely/components/Avatar';
import { ArrawIcon as ArrawIconBase } from '@tymely/components/Menu/NavMenu';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Blink } from '@tymely/utils/styledComponents';
import { getSignedUrl } from '@tymely/api';

import { TicketModal } from '../Ticket/TicketModal';

const ListItem = styled(ListItemBase, { shouldForwardProp: (prop) => prop !== 'outline' })<{ outline?: boolean }>(
    ({ theme, outline }) => `
    margin: 0;
    padding: 0;
    ${
        outline &&
        `
        border: 2px solid ${theme.palette.error.main};
        &.${listItemClasses.root} + .${collapseClasses.hidden} + .${listItemClasses.root} {
            border-top: none;
        }
        `
    }
`,
);

const ItemButton = styled((props: ListItemButtonProps) => <ListItemButton {...props} disableRipple />)<{
    open: boolean;
}>(
    ({ theme, open }) => `
    margin: 0;
    padding: ${theme.spacing(1, 4)};
    background: ${open ? '#FAFBFF' : 'transparent'};
    &:hover {
        background: #F0F3FD;
    }
`,
);

const TitleText = styled((props: TypographyProps) => (
    <Typography {...props} variant="subtitle1" variantMapping={{ subtitle1: 'h6' }} />
))(
    ({ color }) => `
    color: ${color as string | '#353A67'};
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
`,
);

export const DateText = styled(Box)`
    color: #6e6d99;
    font-size: 0.875rem;
`;

const ArrawIcon = styled(ArrawIconBase)(
    () => `
    color: #6E6D99;
`,
);

export const formatDate = (dateStr: string, hasDay = true) => {
    const date = new Date(dateStr);
    if (isToday(date)) return `Today at ${format(date, 'hh:mm ', { locale: enUS })}`;
    return hasDay ? format(date, 'd MMM yyyy', { locale: enUS }) : format(date, 'MMM yyyy', { locale: enUS });
};

const Status = memo((props: { sx?: SxProps; status: ITicket['origin_status'] }) => {
    if (props.status !== 'OPEN' && props.status !== 'CLOSED') return null;
    return (
        <Typography
            display="flex"
            alignItems="center"
            fontSize="fontSmall"
            color={props.status === 'OPEN' ? 'error.main' : 'text.disabled'}
            textTransform="capitalize"
            sx={props.sx}
        >
            <Typography variant="caption" fontSize="fontLarge" lineHeight={1}>
                &#x2022;
            </Typography>
            {props.status.toLowerCase()}
        </Typography>
    );
});

const TicketItem = memo(
    ({
        ticket,
        open,
        highlight,
        onToggleTicket,
        onMergeTicket,
        markedForMerge,
    }: {
        ticket: ITicket;
        open: boolean;
        highlight?: boolean;
        mergeButtonEnabled?: boolean;
        onToggleTicket: (ticket: ITicket) => void;
        onMergeTicket: (ticket: ITicket) => Promise<void>;
        markedForMerge: boolean;
    }) => {
        const theme = useTheme();
        const [loading, setLoading] = useState(false);
        const org = useTicketOrganization();

        return (
            <ListItem>
                <ItemButton open={open} onClick={() => onToggleTicket(ticket)} sx={{}}>
                    <ListItemText
                        sx={{ display: 'flex', alignItems: 'center', flex: 1 }}
                        disableTypography
                        primary={
                            <Box display="flex" alignItems="center" overflow="hidden" mr={1}>
                                <TitleText sx={{ flex: 1 }}>{`#${ticket.id}`}</TitleText>
                                <Tooltip
                                    title={
                                        !org.config.allow_merging_tickets
                                            ? 'Merging is not enabled for this organization'
                                            : ''
                                    }
                                >
                                    <span>
                                        <LoadingButton
                                            disabled={!org.config.allow_merging_tickets}
                                            loading={loading}
                                            loadingPosition="start"
                                            onClick={async (event) => {
                                                setLoading(true);
                                                event.stopPropagation();
                                                onMergeTicket(ticket).finally(() => setLoading(false));
                                            }}
                                            variant="contained"
                                            size="small"
                                            startIcon={markedForMerge ? <MergeIcon /> : <AltRouteIcon />}
                                            sx={{
                                                mr: 1,
                                                ml: 1,
                                                fontSize: theme.typography.fontTiny,
                                                '.MuiButton-startIcon': { mr: 0.5 },
                                            }}
                                            color={markedForMerge ? 'success' : 'secondary'}
                                        >
                                            Merge
                                        </LoadingButton>
                                    </span>
                                </Tooltip>
                                <ArrawIcon open={open} />
                            </Box>
                        }
                        secondary={<Status sx={{ flex: 1, mr: 0.5 }} status={ticket.origin_status} />}
                    />
                    {highlight && (
                        <Blink>
                            <PriorityHighIcon fontSize="small" color="error" />
                        </Blink>
                    )}
                    <DateText>{formatDate(ticket.inquiry_date, false)}</DateText>
                </ItemButton>
            </ListItem>
        );
    },
);

const CommnetText = styled(ListItemText)`
    font-size: 0.875rem;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const CommentItem = memo(
    ({
        comment,
        open,
        selected,
        onSelect,
    }: {
        selected: boolean;
        comment: IComment;
        open: boolean;
        onSelect: (comment: IComment) => void;
    }) => {
        const theme = useTheme();
        const sx = selected
            ? {
                  background: `${theme.palette.common.selected}!important`,
                  color: theme.palette.common.white,
              }
            : {};
        return (
            <ListItem>
                <ItemButton sx={sx} open={open} onClick={() => onSelect(comment)}>
                    <ListItemAvatar sx={{ minWidth: 'auto', mr: 2 }}>
                        <Avatar
                            selected={selected}
                            customer={comment.is_customer}
                            username={comment.is_customer ? comment.from_name : 'Ty'}
                        />
                    </ListItemAvatar>
                    <CommnetText
                        disableTypography
                        primary={
                            <Box display="flex" alignItems="center">
                                <TitleText sx={sx} marginRight={0.5}>
                                    {comment.from_name}
                                </TitleText>
                                <DateText sx={sx} marginLeft="auto">
                                    {formatDate(comment.inquiry_date)}
                                </DateText>
                            </Box>
                        }
                        secondary={comment.body}
                    />
                </ItemButton>
            </ListItem>
        );
    },
);

const List = styled(ListBase)(
    ({ theme }) => `
    height: 0;
    flex: 1;
    width: 100%;
    overflow: auto;
    margin: 0;
    padding: 0;
    padding-bottom: ${theme.spacing(6)};
`,
);

export const Title = styled(
    ({ textColor, ...props }: ListSubheaderProps & { textColor?: TypographyProps['color'] }) => (
        <ListSubheader {...props}>
            <TitleText color={textColor}>{props.children}</TitleText>
        </ListSubheader>
    ),
)(
    ({ theme }) => `
    padding-left: ${theme.spacing(5)};
    margin-bottom: ${theme.spacing(4)};
    background: transparent;
`,
);

export const TicketsHistory = memo(
    forwardRef((props: { onRecentTickets?: (tickets: ITicket[]) => void }, ref: ForwardedRef<HTMLUListElement>) => {
        const { history, error, toggleTicketMerge, isTicketMarkedForMerge } = useTicketHistory();
        const [openTicket, setOpenTicket] = useState<ITicket>();
        const [selectedComment, setSelectedComment] = useState<IComment>();
        const ticket = useTicket();

        const [openedTickets, setOpenedTickets] = useState(new Set<ITicket['id']>());
        const onToggle = useCallback(
            (ticket: ITicket) => {
                if (ticket === openTicket) {
                    setOpenTicket(undefined);
                } else {
                    setOpenedTickets((prev) => new Set([...prev, ticket.id]));
                    setOpenTicket(ticket);
                }
            },
            [openTicket],
        );

        const onSelect = useCallback((comment: IComment) => {
            setSelectedComment(comment);
            window.location.hash = String(comment.id);
        }, []);

        const [historyTickets, setHistoryTickets] = useState<ITicket[]>();

        useEffect(() => {
            if (history?.tickets) {
                setHistoryTickets(history?.tickets);
            }
        }, [history?.tickets]);

        const historyMessage = !historyTickets ? 'Loading...' : `Customer History (${(historyTickets || []).length})`;
        const [recentTickets, setRecentTickets] = useState<ITicket[]>([]);
        useEffect(() => {
            const recentTickets = historyTickets?.filter((ticket) => {
                const date = new Date(ticket.inquiry_date);
                return addDays(date, 30) >= new Date();
            });
            setRecentTickets(recentTickets || []);
            props.onRecentTickets?.(recentTickets || []);
        }, [historyTickets]);

        useSubscription(
            `ticket/${ticket?.id}`,
            ['TicketHistory', 'Error'],
            async (event: IEvent) => {
                if (event.data?.url) {
                    const s3Url = event.data.url.toString();
                    getSignedUrl(s3Url).then((url: string) => {
                        fetch(url)
                            .then((response) => response.text())
                            .then((data) => {
                                const json = JSON.parse(data);
                                setHistoryTickets((json as TicketHistoryInfo).tickets);
                            });
                    });
                }
            },
            [ticket?.id],
        );

        return (
            <>
                <List
                    ref={ref}
                    subheader={
                        <Title textColor={error ? 'error' : 'default'}>{error ? error.message : historyMessage}</Title>
                    }
                >
                    {historyTickets?.map((ticket) => (
                        <Fragment key={ticket.id}>
                            <TicketItem
                                open={ticket === openTicket}
                                ticket={ticket}
                                highlight={recentTickets.includes(ticket) && !openedTickets.has(ticket.id)}
                                mergeButtonEnabled={!['NEW', 'OPEN'].includes(ticket.origin_status)}
                                onToggleTicket={onToggle}
                                onMergeTicket={toggleTicketMerge}
                                markedForMerge={isTicketMarkedForMerge(ticket)}
                            />
                            <Collapse in={ticket === openTicket} timeout="auto" component="li">
                                <List sx={{ p: 0, height: '100%' }}>
                                    {ticket.comments.map((comment) => (
                                        <CommentItem
                                            key={comment.id}
                                            selected={comment === selectedComment}
                                            open={ticket === openTicket}
                                            comment={comment}
                                            onSelect={onSelect}
                                        />
                                    ))}
                                </List>
                            </Collapse>
                        </Fragment>
                    ))}
                </List>
                {openTicket && selectedComment && (
                    <TicketModal ticket={openTicket} selectedCommentId={selectedComment.id} />
                )}
            </>
        );
    }),
);
