import omit from 'lodash/omit';
import { forwardRef, memo, Ref } from 'react';
import { useState } from 'react';
import { Autocomplete, createFilterOptions, TextField, AutocompleteValue, MenuItem, useTheme } from '@mui/material';
import { IArgumentMetadata } from '@tymely/atoms';
import styled from 'styled-components';

import { styledPopper } from '../Popper';

const filter = createFilterOptions<IArgumentMetadata>();

const defaultArgMetadata: IArgumentMetadata = {
    id: -1,
    title: '',
    description: '',
    extractor_name: 'user_input',
    dtype: 'str',
    arg_type: 'USER_INPUT',
    scope: 'COMMENT',
    is_list: false,
    is_ticket_arg: false,
    unspecifiable: true,
    dependencies: [],
    name: '',
    params: {},
    additional_data: {},
    created_date: new Date(),
};

const StyledTextField = styled(TextField)`
    .MuiInputBase-root {
        background-color: white;
    }
`;

export const ArgSearchBox = memo(
    forwardRef(
        (
            props: {
                hint: string;
                args: IArgumentMetadata[];
                getOptionDisabled: (arg: IArgumentMetadata) => boolean;
                addNewArgument: (arg: Omit<IArgumentMetadata, 'id'>) => void;
                onSelect: (arg: IArgumentMetadata) => void;
                onBlur?: () => void;
                disabled?: boolean;
            },
            textFieldRef: Ref<HTMLInputElement>,
        ) => {
            const [value, setValue] = useState<IArgumentMetadata | null>(null);
            const [inputValue, setInputValue] = useState('');

            const handleSelectOption = (
                newValue: AutocompleteValue<IArgumentMetadata | string, undefined, undefined, undefined>,
            ) => {
                if (typeof newValue === 'string') {
                    props.addNewArgument({ ...omit(defaultArgMetadata, 'id'), name: newValue });
                } else if (newValue?.id === -1) {
                    props.addNewArgument({ ...omit(defaultArgMetadata, 'id'), name: newValue.name });
                } else {
                    if (newValue) {
                        props.onSelect(newValue);
                    }
                    setValue(null);
                }
                setInputValue('');
            };

            const theme = useTheme();

            return (
                <Autocomplete
                    id="argument-selector"
                    disabled={props.disabled}
                    inputValue={inputValue}
                    onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
                    value={value}
                    onChange={(_, newValue) => handleSelectOption(newValue)}
                    onBlur={props.onBlur}
                    PopperComponent={styledPopper({ width: 'fit-content', maxWidth: theme.spacing(60) })}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (
                            params.inputValue !== '' &&
                            props.args.filter((arg) => arg.title === params.inputValue).length === 0
                        ) {
                            filtered.push({ ...defaultArgMetadata, name: params.inputValue });
                        }

                        return filtered;
                    }}
                    options={props.args}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                    renderOption={(_, option) => {
                        if (
                            option.id === -1 &&
                            props.args.find((arg) => arg.name.toLowerCase() === option.name.toLowerCase())
                        ) {
                            return null;
                        }

                        return (
                            <MenuItem
                                key={option.id}
                                title={option.name}
                                onClick={() => handleSelectOption(option)}
                                disabled={props.getOptionDisabled(option)}
                            >
                                {option.id === -1 ? `Add "${option.name}"` : option.name}
                            </MenuItem>
                        );
                    }}
                    selectOnFocus
                    blurOnSelect
                    clearOnBlur
                    fullWidth
                    size="small"
                    freeSolo
                    sx={{ height: 40 }}
                    renderInput={(params) => <StyledTextField {...params} inputRef={textFieldRef} label={props.hint} />}
                />
            );
        },
    ),
);
