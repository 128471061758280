import { ComponentProps, forwardRef, ForwardedRef } from 'react';
import { styled } from '@mui/material';

const Img = styled('img')({
    width: 'inherit',
    height: 'inherit',
});

export const Image = forwardRef((props: ComponentProps<typeof Img>, ref: ForwardedRef<HTMLImageElement>) => (
    <Img {...props} ref={ref} />
));
