import { Node, Edge } from 'reactflow';
import _ from 'lodash';

interface Graph<D, E> {
    nodes: Node<D>[];
    edges: Edge<E>[];
}

export const getChildren = <D, E>(nodeId: Node['id'], graph: Graph<D, E>): Node<D>[] => {
    const childNodeIds = graph.edges.filter(({ source }) => source === nodeId).map(({ target }) => target);
    return graph.nodes.filter(({ id }) => childNodeIds.includes(id)) as Node<D>[];
};

export const getDescendants = <D, E>(nodeId: Node['id'], graph: Graph<D, E>): Node<D>[] => {
    const children = getChildren(nodeId, graph);

    return _.uniqBy(
        [...children, ...children.map((child) => getDescendants(child.id, graph)).flat()],
        'id',
    ) as Node<D>[];
};

export const getParent = <D, E>(nodeId: Node['id'], graph: Graph<D, E>): Node<D> | null => {
    const parentId = graph.edges.find(({ target }) => target === nodeId)?.source;
    return (graph.nodes.find(({ id }) => id === parentId) ?? null) as Node<D> | null;
};

const getParentFromMap = <D>(
    nodeId: Node['id'],
    nodesMap: Map<Node['id'], Node<D>>,
    edgesRevMap: Map<Node['id'], Node['id']>,
): Node<D> | undefined => {
    const parentId = edgesRevMap.get(nodeId);
    if (!parentId) {
        return undefined;
    }
    return nodesMap.get(parentId);
};

export const getAncestors = <D, E>(nodeId: Node['id'], graph: Graph<D, E>): Node<D>[] => {
    const edgesRevMap = new Map(graph.edges.map((e) => [e.target, e.source]));
    const nodesMap = new Map(graph.nodes.map((node) => [node.id, node as Node<D>]));

    const ancestors = [];
    for (
        let parent = getParentFromMap<D>(nodeId, nodesMap, edgesRevMap);
        parent !== undefined;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        parent = getParentFromMap<D>(parent?.id ?? '', nodesMap, edgesRevMap)
    ) {
        ancestors.push(parent);
        parent = getParentFromMap(parent.id, nodesMap, edgesRevMap);
    }

    return ancestors;
};

export const getIncomingEdge = <D, E>(nodeId: Node['id'], graph: Graph<D, E>): Graph<D, E>['edges'][number] | null => {
    return graph.edges.find((e) => e.target === nodeId) ?? null;
};

export const isAncestor = <D, E>(childId: Node['id'], ancestorId: Node['id'], graph: Graph<D, E>): boolean => {
    return getAncestors(childId, graph).some((node) => node.id === ancestorId);
};

export const getDepth = <D, E>(
    nodeId: Node['id'],
    graph: Graph<D, E>,
    countOnly?: (targetNodeId: Node['id']) => boolean,
): number => {
    const parent = getParent(nodeId, graph);

    if (!parent) {
        return 0;
    }

    const countNode = countOnly ? countOnly(nodeId) : true;
    return (countNode ? 1 : 0) + getDepth(parent.id, graph, countOnly);
};

export const getMaxDistanceFromLeaf = <D, E>(nodeId: Node['id'], graph: Graph<D, E>): number => {
    const children = getChildren(nodeId, graph);

    if (children.length === 0) {
        return 0;
    }

    return 1 + Math.max(...children.map(({ id }) => getMaxDistanceFromLeaf(id, graph)));
};

export const getLeaves = <D, E>(nodeId: Node['id'], graph: Graph<D, E>): Node<D>[] => {
    return [...getDescendants(nodeId, graph), ...graph.nodes.filter(({ id }) => id === nodeId)].filter(
        (node) => !graph.edges.find((edge) => edge.source === node.id),
    ) as Node<D>[];
};

export const stepTowards = <D, E>(
    sourceNodeId: Node['id'],
    targetNodeId: Node['id'],
    graph: Graph<D, E>,
): Node<D> | null => {
    const targetAncestorIds = getAncestors(targetNodeId, graph).map((n) => n.id);
    return getChildren(sourceNodeId, graph).find((n) => targetAncestorIds.includes(n.id)) ?? null;
};
