import { memo, MouseEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Divider, IconButton, ListItemIcon, Menu, MenuItem, styled, Tooltip, Typography } from '@mui/material';
import { routes, version } from '@tymely/config';
import { SignOut } from '@tymely/services';

import { UserAvatar } from '../UserAvatar';

const UserName = styled(Typography)({
    display: 'flex',
    justifyContent: 'center',
});

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
    marginLeft: theme.spacing(2),
}));

const StyledLink = styled(Link)({
    textDecoration: 'none',
});

const adminMenuItems = {
    admin: <StyledLink to={routes.admin}>Admin</StyledLink>,
    policyEditor: <StyledLink to={routes.policyEditor}>Policy editor</StyledLink>,
    argumentsMetadata: <StyledLink to={routes.argumentMetadataView}>Arguments Metadata</StyledLink>,
    actionsMetadata: <StyledLink to={routes.actionMetadataView}>Actions Metadata</StyledLink>,
    templateEditor: <StyledLink to={routes.templateEditor}>Template Editor</StyledLink>,
    organizationEditor: <StyledLink to={routes.organizationEditor}>Organization Editor</StyledLink>,
    openApi: <StyledLink to={routes.adminOpenApiRoutes}>Open API</StyledLink>,
};

export const AccountMenu = memo((props: { user: { username?: string; isAdmin?: boolean } }) => {
    const isAdmin = props.user?.isAdmin;
    const username = props.user?.username;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <Tooltip title="Account settings" id="account-menu-tooltip">
                <IconButtonStyled
                    id="user-account-icon-btn"
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <UserAvatar />
                </IconButtonStyled>
            </Tooltip>
            <Menu
                id="account-menu"
                anchorEl={anchorEl}
                open={open}
                onClick={handleClose}
                onClose={handleClose}
                PaperProps={{
                    elevation: 5,
                    // TODO: change to styled
                    sx: {
                        overflow: 'visible',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 16,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {username && (
                    <MenuItem sx={{ pt: 0, pb: 0 }}>
                        <UserName variant="subtitle1" sx={{ textTransform: 'capitalize', margin: 'auto' }}>
                            {username}
                        </UserName>
                    </MenuItem>
                )}
                <Divider />
                {isAdmin &&
                    Object.entries(adminMenuItems).map(([key, item]) => (
                        <MenuItem id={`account-admin-menu-item-${item}`} key={key}>
                            {item}
                        </MenuItem>
                    ))}
                {isAdmin && <Divider />}
                <MenuItem id="version-menu-item">
                    <ListItemIcon>
                        <VerifiedIcon fontSize="small" />
                    </ListItemIcon>
                    {version}
                </MenuItem>
                <MenuItem onClick={() => SignOut()} id="account-menu-item-logout">
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </>
    );
});
