import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import { memo, useCallback, useLayoutEffect, useMemo, useState, useRef } from 'react';
import { FormControl, InputLabel } from '@mui/material';
import {
    IArgumentMultiCategory,
    ICategoryItem,
    IArgumentCategory,
    IArgument,
    IArgumentDisplayRegulator,
    IArgumentString,
    isCategorical,
} from '@tymely/atoms';
import { Option } from '@tymely/components';
import { Nullable, typedMemo } from '@global/types';

import { ArgumentFieldProps, StyledMultiSelect } from './Layout';
import { isCategoricalArgChanged } from './MultiCategoryArgument';

export const GroupedMultiSelect = typedMemo(function GroupedMultiSelect(props: {
    argument: IArgumentMultiCategory<ICategoryItem>;
    groupArg: IArgumentCategory | IArgumentMultiCategory<string>;
    displayRegulatorArg?: IArgumentDisplayRegulator;
    searchArgument?: IArgumentString;
    options: Option<string[]>[];
    multiple: boolean;
    loading?: boolean;
    disabled?: boolean;
    withLabel?: boolean;
    onChange?: (value: IArgument[]) => unknown;
}) {
    const [initValue, setInitValue] = useState<typeof props.argument.value>(props.argument.value);
    const [argValue, setArgValue] = useState<typeof props.argument.value>(props.argument.value);

    useLayoutEffect(() => {
        setArgValue(props.argument.value);
        setInitValue(props.argument.value);
    }, [props.argument.value]);

    const selectedOptions = useMemo(
        () =>
            props.options.filter(
                (item) =>
                    item.value.length &&
                    item.value.every((value) =>
                        Array.isArray(argValue) ? argValue.includes(value) : value === argValue,
                    ),
            ),
        [argValue, props.options],
    );

    const optionSelected = useRef<boolean>(false);
    const onSelectOptions = useCallback(
        (selected: Nullable<Option<string[]>[]>) => {
            const itemIds = selected && selected.map((item) => item.value).flat();
            setArgValue((props.multiple ? itemIds : itemIds?.[0] || null) as typeof props.argument.value);
            // Special case when neither is selected.
            if (selected === null) {
                onChange(null).catch(() => setArgValue(initValue));
            } else {
                optionSelected.current = true;

                if (props.argument.lazy && selected && selected.length) {
                    const groupsSelected = selected.map((item) => item.group?.id).filter(Boolean);
                    const isLazyGroupSelected = selected.find((item) => item.value.length === 0);
                    if (isLazyGroupSelected) {
                        const revealedGroups = uniq(
                            Object.entries(props.argument.categories)
                                .map(([_, option]) =>
                                    props.groupArg.group_by_label
                                        ? props.groupArg.categories[option.group]
                                        : option.group,
                                )
                                .concat(groupsSelected),
                        );
                        props.onChange?.([
                            props.groupArg.is_list
                                ? {
                                      ...(props.groupArg as IArgumentMultiCategory<string>),
                                      value: revealedGroups,
                                  }
                                : {
                                      ...(props.groupArg as IArgumentCategory),
                                      value: groupsSelected[0],
                                  },
                        ]);
                    }
                }
            }
        },
        [props.multiple, initValue, props.onChange],
    );

    const onClose = useCallback(() => {
        if (optionSelected.current) {
            onChange(argValue).catch(() => setArgValue(initValue));
        }
    }, [argValue, initValue, props.onChange]);

    const onChange = useCallback(
        async (argValue: typeof props.argument.value) => {
            if (!isCategoricalArgChanged(props.argument, argValue)) return;

            const selectedGroups = argValue
                ? uniq(
                      props.options
                          .filter(
                              (option) =>
                                  option.value.length &&
                                  option.value.every((value) =>
                                      Array.isArray(argValue) ? argValue.includes(value) : argValue === value,
                                  ),
                          )
                          .map((option) => option.group?.id)
                          .filter(Boolean),
                  )
                : null;

            return props.onChange?.([
                props.groupArg.is_list
                    ? {
                          ...(props.groupArg as IArgumentMultiCategory<string>),
                          value: selectedGroups,
                      }
                    : {
                          ...(props.groupArg as IArgumentCategory),
                          value: (selectedGroups && selectedGroups[0]) || null,
                      },
                {
                    ...props.argument,
                    value: argValue,
                },
            ]);
        },
        [props.argument, props.groupArg, props.options, props.onChange],
    );

    const loadMore = useCallback(() => {
        if (!props.displayRegulatorArg) {
            return;
        }

        props.onChange?.([{ ...props.displayRegulatorArg, value: !props.displayRegulatorArg.value }]);
    }, [props.displayRegulatorArg, props.onChange]);

    const searchMore = useCallback(
        (searchTerm: string) => {
            if (!props.searchArgument) {
                return;
            }

            props.onChange?.([{ ...props.searchArgument, value: searchTerm }]);
        },
        [props.searchArgument, props.onChange],
    );

    return (
        <FormControl size="small" fullWidth>
            {props.withLabel && <InputLabel>{props.argument.name}</InputLabel>}
            <StyledMultiSelect
                options={props.options}
                multiple={props.multiple}
                multiGroup={props.groupArg.is_list}
                value={selectedOptions}
                grouped={Boolean(props.groupArg)}
                neitherable={props.argument.neitherable}
                edited={props.argument.is_edited}
                unspecified={props.argument.is_unspecified}
                neither={!props.argument.value?.length && props.argument.is_edited}
                loading={props.loading}
                disabled={props.disabled}
                onClose={onClose}
                onOptsSelect={onSelectOptions}
                loadMore={props.displayRegulatorArg?.value !== false ? loadMore : undefined}
                searchMore={props.searchArgument ? searchMore : undefined}
                searchHint={props.searchArgument && `Search by ${props.searchArgument.title.toLowerCase()}...`}
            />
        </FormControl>
    );
});

const getItems = (
    itemsCategories: NonNullable<IArgumentMultiCategory<ICategoryItem>['categories']>,
    groupCategories: NonNullable<IArgumentCategory['categories'] | IArgumentMultiCategory<string>['categories']>,
    allowEmptyGroups = false,
    groupByLabel = false,
) => {
    const sortMap = Object.fromEntries(Object.keys(groupCategories).map((groupId, at) => [groupId, at]));

    let items = Object.entries<ICategoryItem>(itemsCategories)
        .map(([key, value]) => ({
            value: [key],
            name: value.title || value.name,
            group: {
                id: groupByLabel ? groupCategories[value.group] : value.group,
                name: groupCategories[value.group],
            },
        }))
        .flat();

    if (allowEmptyGroups) {
        const nonEmptyGroups = new Set(items.map((item) => item.group.id));

        const emptyGroupsItems = uniqBy(
            Object.entries(groupCategories)
                .filter(([groupId]) => !nonEmptyGroups.has(groupId))
                .map(([groupId, groupName]) => ({
                    value: [],
                    name: groupName,
                    group: {
                        id: groupByLabel ? groupName : groupId,
                        name: groupName,
                    },
                })),
            (option) => option.group.id,
        );
        items = items.concat(emptyGroupsItems);
    }

    return sortBy(items, (item) => sortMap[item.group.id]);
};

export const GroupedMultiCategoryArgument = memo(
    (
        props: ArgumentFieldProps<IArgumentMultiCategory<ICategoryItem>> & {
            groupArg: IArgumentCategory | IArgumentMultiCategory<string>;
            displayRegulatorArg?: IArgumentDisplayRegulator;
            searchArgument?: IArgumentString;
        },
    ) => {
        const groupArg = useMemo(() => {
            return {
                ...props.groupArg,
                value:
                    props.groupArg.value && props.groupArg.group_by_label && isCategorical(props.groupArg)
                        ? props.groupArg.is_list
                            ? (props.groupArg as IArgumentMultiCategory<string>).value?.map(
                                  (id) => props.groupArg.categories[id],
                              )
                            : [props.groupArg.categories[(props.groupArg as IArgumentCategory).value!]]
                        : props.groupArg.value,
            } as IArgumentMultiCategory<string>;
        }, [props.groupArg]);

        const options = useMemo(() => {
            return (
                props.argument.categories &&
                groupArg.categories &&
                getItems(props.argument.categories, groupArg.categories, props.argument.lazy, groupArg.group_by_label)
            );
        }, [props.argument.categories, groupArg.categories]);

        const groupByLabelMap = useMemo(() => {
            if (!props.groupArg.group_by_label) return null;
            return Object.entries(props.groupArg.categories).reduce(
                (map, [id, label]) => {
                    const ids = (map[label] = map[label] || []);
                    ids.push(id);
                    return map;
                },
                {} as { [key: string]: string[] },
            );
        }, [props.groupArg]);

        return (
            <GroupedMultiSelect
                argument={props.argument}
                groupArg={groupArg}
                displayRegulatorArg={props.displayRegulatorArg}
                searchArgument={props.searchArgument}
                multiple={props.argument.is_list}
                loading={props.loading}
                disabled={props.disabled}
                options={options || []}
                withLabel={props.withLabel}
                onChange={(args) => {
                    return props.onChange?.(
                        args.map((arg) => {
                            if (arg.id === props.groupArg.id && groupByLabelMap) {
                                return {
                                    ...arg,
                                    value: (arg as IArgumentMultiCategory<string>)
                                        .value!.map((label) => groupByLabelMap[label])
                                        .flat(),
                                } as IArgument;
                            }
                            return arg;
                        }),
                    );
                }}
            />
        );
    },
);
