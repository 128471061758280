export enum IDineshTicketOperations {
    USER_CLICKED_OK = 'USER_CLICKED_OK',
    USER_CLICKED_CHAT_ALERT = 'USER_CLICKED_CHAT_ALERT',
    USER_TAGGED_TICKET = 'USER_LABELED_TICKET',
    USER_UNTAGGED_TICKET = 'USER_UNLABELED_TICKET',
    USER_RESET_TICKET = 'USER_RESET_TICKET',
    USER_SENT_TICKET_FOR_REVIEW = 'USER_SENT_TICKET_FOR_REVIEW',
    USER_SENT_TICKET_FOR_ANALYST = 'USER_SENT_TICKET_FOR_ANALYST',
    USER_SUBMITTED_TICKET = 'USER_SUBMITTED_TICKET',
    USER_SAVED_TICKET = 'USER_SAVED_TICKET',
    USER_REDIRECTED_TO_NEXT_TICKET = 'USER_REDIRECTED_TO_NEXT_TICKET',
    USER_SAW_TICKET = 'USER_SAW_TICKET',
    USER_EDITED_TICKET_ARGUMENTS = 'USER_EDITED_TICKET_ARGUMENTS',
    USER_SEARCHED_INTENT = 'USER_SEARCHED_INTENT',
    USER_EXPANDED_INTENTS_CATEGORY = 'USER_EXPANDED_INTENTS_CATEGORY',
    USER_REPORTED_TICKET = 'USER_REPORTED_TICKET',
    USER_SAVED_NOTES = 'USER_SAVED_NOTES',
    USER_GOT_UPDATED_ARGUMENTS = 'USER_GOT_UPDATED_ARGUMENTS',
    USER_ESCALATED_TICKET = 'USER_ESCALATED_TICKET',
    TICKET_SUBMISSION_PREVIEW_APPROVED = 'TICKET_SUBMISSION_PREVIEW_APPROVED',
    TICKET_SUBMISSION_PREVIEW_CANCELLED = 'TICKET_SUBMISSION_PREVIEW_CANCELLED',
    USER_TAGGED_UNSUPPORTED_INTENT = 'USER_TAGGED_UNSUPPORTED_INTENT',
    USER_SAW_CHAT_TICKET_ALERT = 'USER_SAW_CHAT_TICKET_ALERT',
    USER_WAITED = 'USER_WAITED',
    USER_APPROVED_ARGUMENT = 'USER_APPROVED_ARGUMENT',
    USER_APPROVED_PREDICTED_INTENT = 'USER_APPROVED_PREDICTED_INTENT',
    USER_REVEALED_HIDDEN_ARGS = 'USER_REVEALED_HIDDEN_ARGS',
    USER_WAITED_FOR_NEW_TICKET = 'USER_WAITED_FOR_NEW_TICKET',
}

export enum ITicketTrailStatus {
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
}

export type ICrumbAdditionalData = Record<string, any>;

export interface ITicketTrailCrumb {
    triggered_by?: string;
    operation: IDineshTicketOperations;
    status: string;
    origin_ticket_id?: string;
    ticket_id: number;
    organization_id: number;
    comment_id?: number;
    additional_data: ICrumbAdditionalData;
}

export interface ITicketTrailEvent {
    id: number;
    created_date: Date;
    triggered_by?: string;
    operation: string;
    status: string;
    origin_ticket_id?: string;
    ticket_id: number;
    organization_id: number;
    additional_data: ICrumbAdditionalData;
}
