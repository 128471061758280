export default () => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.7969 16.7065L18.9874 22.8969C20.0125 23.9221 21.6765 23.9221 22.7016 22.8969V22.8969C23.7268 21.8718 23.7268 20.2078 22.7016 19.1827L16.5112 12.9922"
                stroke="currentColor"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.0379 5.37845C24.0676 5.40693 24.0899 5.44407 24.1011 5.48369C24.683 7.42378 24.2323 9.60655 22.6995 11.1393C21.1507 12.6882 18.9382 13.1314 16.9832 12.5223L6.64887 22.8566C5.64229 23.8632 4.00058 23.9387 2.95934 22.9681C1.86487 21.9491 1.84258 20.2356 2.89001 19.1869L13.269 8.80797C12.6598 6.85302 13.1031 4.64055 14.6519 3.09169C16.1847 1.55893 18.3674 1.10826 20.3075 1.69016C20.3472 1.70255 20.3843 1.72359 20.4128 1.75331L20.6133 1.95388C20.7099 2.05045 20.7099 2.20769 20.6133 2.30426L17.351 5.56788L20.2233 8.44026L23.487 5.17664C23.5835 5.08007 23.7408 5.08007 23.8373 5.17664L24.0379 5.37845V5.37845Z"
                stroke="currentColor"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.774 3.45665L2.60847 5.96131C2.77685 6.46769 3.24981 6.80817 3.78343 6.80817H6.60505V3.98655C6.60505 3.45417 6.26457 2.97998 5.75819 2.8116L3.25352 1.97712C3.03066 1.90284 2.78552 1.96103 2.61962 2.12693L1.92381 2.82274C1.7579 2.98865 1.69971 3.23379 1.774 3.45665V3.45665Z"
                stroke="currentColor"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.61133 6.8125L10.9447 11.1458"
                stroke="currentColor"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
