import uniq from 'lodash/uniq';
import { Typography, useTheme } from '@mui/material';
import { pluralize } from '@tymely/utils';
import { ITicket } from '@tymely/atoms';
import PartyPopperIcon from '@tymely/components/Icons/PartyPopper';
import { useTicket } from '@tymely/services';
import { useEffect, useMemo, useState } from 'react';

const EVENT_KEY = 'tymely:reviewed-tickets';

type Timeline = { [key: string]: ITicket['id'] };

export const ReviewSpeedometer = () => {
    const theme = useTheme();

    const [timeline, setTimeline] = useState<Timeline>();

    useEffect(() => {
        setTimeline(JSON.parse(localStorage.getItem(EVENT_KEY) || '{}') as Timeline);
        const listener = (event: StorageEvent) => {
            if (event.key === EVENT_KEY && event.newValue) {
                setTimeline(JSON.parse(event.newValue) as Timeline);
            }
        };
        window.addEventListener('storage', listener);
        return () => window.removeEventListener('storage', listener);
    }, []);

    const ticket = useTicket();

    const totalReviewedTicketsOverPastHour = useMemo(
        () => uniq(Object.values(timeline || {})).filter((id) => id !== ticket.id).length,
        [timeline, ticket],
    );

    useEffect(() => {
        if (!timeline) return;
        if (ticket.id) {
            const newTimeline = {
                [new Date().toISOString()]: ticket.id,
            };
            for (const timestamp in timeline) {
                if (new Date().getTime() - new Date(timestamp).getTime() < 60 * 60 * 1000) {
                    newTimeline[timestamp] = timeline[timestamp];
                }
            }
            localStorage.setItem(EVENT_KEY, JSON.stringify(newTimeline));
        }
    }, [ticket.id, timeline]);

    const performanceLevel = useMemo(() => {
        if (totalReviewedTicketsOverPastHour > 20) {
            return 'success';
        } else if (totalReviewedTicketsOverPastHour > 10) {
            return 'warning';
        } else {
            return 'error';
        }
    }, [totalReviewedTicketsOverPastHour]);

    return (
        <Typography
            sx={{
                color: theme.palette[performanceLevel].dark,
                fontWeight: 'bold',
                display: 'inline-flex',
                alignItems: 'center',
                gap: 0.66,
                border: '1px solid ' + theme.palette[performanceLevel].dark,
                padding: 1,
                borderRadius: 1,
                letterSpacing: 0.88,
                mb: -1,
            }}
            variant="body1"
        >
            {performanceLevel === 'success' && <PartyPopperIcon />}
            <Typography variant="h4" sx={{ lineHeight: 1, fontWeight: 500 }}>
                {totalReviewedTicketsOverPastHour}
            </Typography>
            <Typography variant="caption" sx={{ textTransform: 'uppercase', fontWeight: 600, lineHeight: 1.25 }}>
                {pluralize('ticket', totalReviewedTicketsOverPastHour)} reviewed
                <br />
                over the past hour
            </Typography>
        </Typography>
    );
};
