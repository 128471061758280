import { useState, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { chain, findLast } from 'lodash';
import { fetchCommentsSince, fetchUserTicketHistory } from '@tymely/api';
import { IComment, TicketHistoryInfo } from '@tymely/atoms';

import { useSetTicket, useTicket, useTicketOrganization } from './ticket.services';
import {
    useSetCommentIdToFocusOn,
    DECISION_QUERY_KEY,
    ARGUMENTS_QUERY_KEY,
    AGENT_RESPONSE_QUERY_KEY,
    useCommentIdToFocusOn,
} from './comment.services';

export const groupComments = (comments: IComment[]): { date: string; comments: IComment[] }[] =>
    chain(comments)
        .groupBy((comment: IComment) => new Date(comment.inquiry_date).toDateString())
        .map((comments, date) => ({ date, comments }))
        .sortBy((comment) => new Date(comment.date), ['asc'])
        .value();

export const useConversation = () => {
    const { config } = useTicketOrganization();
    const ticket = useTicket();
    const setTicket = useSetTicket();
    const commentIdToFocusOn = useCommentIdToFocusOn();
    const setCommentIdToFocusOn = useSetCommentIdToFocusOn();
    const queryClient = useQueryClient();

    const lastComment = (ticket?.comments || []).at(-1);
    const [pollCreatedSince, setPollCreatedSince] = useState<string | undefined>(lastComment?.created_date);
    const [pollUpdatedSince, setPollUpdatedSince] = useState<string | undefined>(
        lastComment?.updated_at || lastComment?.created_date,
    );

    useQuery(
        ['pollComments', ticket.id],
        async () => {
            if (!ticket?.id) return Promise.reject('Ticket is not loaded');
            return fetchCommentsSince(ticket.id, pollCreatedSince, pollUpdatedSince);
        },
        {
            refetchInterval: 1500,
            refetchIntervalInBackground: true,
            refetchOnMount: false,
            retry: false,
            enabled:
                false && config.chat_enabled && (ticket.origin_status === 'NEW' || ticket.origin_status === 'OPEN'),
            onSuccess: (updates) => {
                if (updates.length) {
                    const maxCreatedAt = updates.reduce(
                        (maxCreatedAt, comment) =>
                            maxCreatedAt > comment.created_date ? maxCreatedAt : comment.created_date,
                        '',
                    );
                    setPollCreatedSince(maxCreatedAt);

                    const maxUpadatedAt = updates.reduce(
                        (maxUpdatedAt, comment) =>
                            comment.updated_at && maxUpdatedAt > comment.updated_at ? maxUpdatedAt : comment.updated_at,
                        '',
                    );
                    setPollUpdatedSince(maxUpadatedAt);

                    const updatedIds = {} as { [key: number]: boolean };
                    setTicket({
                        ...ticket,
                        comments: ticket.comments
                            .map((comment) => {
                                const updatedComment = updates.find(
                                    (updatedComment) => updatedComment.id === comment.id,
                                );
                                if (updatedComment) {
                                    updatedIds[comment.id] = true;
                                }
                                return updatedComment || comment;
                            })
                            .concat(updates.filter((update) => !updatedIds[update.id])), // new comments
                    });

                    const lastCustomerCommentId = findLast(ticket.comments, (comment) => comment.is_customer)?.id;

                    return Promise.all([
                        queryClient.invalidateQueries([DECISION_QUERY_KEY, lastCustomerCommentId]),
                        queryClient.invalidateQueries([ARGUMENTS_QUERY_KEY, lastCustomerCommentId]),
                        queryClient.invalidateQueries([AGENT_RESPONSE_QUERY_KEY, lastCustomerCommentId]),
                    ]);
                }
                return Promise.resolve();
            },
        },
    );

    const { data: history, isFetched: historyFetched } = useQuery<TicketHistoryInfo>(
        ['ticketHistory', ticket.id],
        () => {
            return fetchUserTicketHistory(ticket.id, ticket.organization_id, ticket.origin_customer_id, true);
        },
        {
            enabled: !ticket.organization.disabled,
            staleTime: Infinity,
        },
    );

    const groupedComments = useMemo(() => groupComments(ticket.comments), [ticket]);

    return {
        ticket,
        comments: groupedComments,
        history,
        commentIdToFocusOn,
        loaded: Boolean(ticket?.comments) && historyFetched,
        setCommentIdToFocusOn,
    };
};
