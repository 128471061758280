import React from 'react';
import {
    Box,
    BoxProps,
    Button,
    ButtonProps,
    Chip,
    ChipProps,
    ListItem,
    styled,
    Typography,
    TypographyProps,
} from '@mui/material';
import EyeIcon from '@tymely/components/Icons/Eye';
import { lighten } from 'polished';
import { IComment } from '@tymely/atoms';

const _CurrentLabelRoot = styled(Box)`
    flex: 1;
`;

const _CurrentLabel = styled(Typography)`
    margin-right: ${({ theme }) => theme.spacing(1)};
    white-space: nowrap;
    color: ${({ theme }) => theme.palette.primary.dark};
` as typeof Typography;

export const CurrentLabel = () => {
    return (
        <_CurrentLabelRoot>
            <_CurrentLabel component="span" variant="subtitle2">
                <EyeIcon />
                &nbsp;Current
            </_CurrentLabel>
        </_CurrentLabelRoot>
    );
};

const _InfoListItemRoot = styled(ListItem)`
    padding-right: ${({ theme }) => theme.spacing(4)};
    padding-left: ${({ theme }) => theme.spacing(5)};
    align-items: flex-start;
    flex-direction: column;
`;

type InfoListItemProps = { label: string; value?: string };

export const InfoListItem = React.memo(({ label, value }: InfoListItemProps) => (
    <_InfoListItemRoot>
        <Typography variant="subtitle2" color="secondary">
            {label}
        </Typography>
        <Typography variant="body2" whiteSpace="nowrap">
            {value}
        </Typography>
    </_InfoListItemRoot>
));

type InfoButtonProps = ButtonProps & { open: boolean; onToggle: (open: boolean) => void };

const _Button = styled(Button)`
    padding: 0;
    margin-left: ${({ theme }) => theme.spacing(1)};
    color: #5e74d7;
`;

const _Label = styled(Box)`
    margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const InfoButton = React.memo(({ open, onToggle, ...rest }: InfoButtonProps) => {
    return (
        <_Button
            {...rest}
            href=""
            disableRipple
            onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                onToggle(!open);
            }}
        >
            <_Label component="span">{open ? 'Hide' : 'Show'}</_Label>
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12" fill="none">
                <path d="M1 11L5 6L0.999999 1" stroke="currentColor" strokeWidth="1.47422" strokeLinecap="round" />
            </svg>
        </_Button>
    );
});

const _CommentChip = styled(Chip)`
    font-size: ${({ theme }) => theme.typography.fontTiny};
    font-weight: 400;
    padding: ${({ theme }) => theme.spacing(1)};
    .MuiChip-deleteIcon {
        margin-right: -3px;
    }
`;

export const CommentChip = (props: ChipProps) => <_CommentChip {...props} size="small" variant="filled" />;

const _DatetimeTypo = styled(Typography)`
    font-size: ${({ theme }) => theme.typography.fontSmall};
    color: ${({ theme }) => theme.palette.secondary.main};
    width: 100%;
    text-align: right;
    white-space: nowrap;
    margin-left: auto;
    flex: 0;
`;

export const DatetimeTypo = (props: TypographyProps) => <_DatetimeTypo variant="caption" {...props} />;

type BubbleContainerProps = {
    comment: IComment;
    focusedOn?: boolean;
    live?: boolean;
};

export const BubbleContainer = styled(Box, {
    shouldForwardProp: (prop: string) => !['comment', 'focusedOn', 'live'].includes(prop),
})<BubbleContainerProps>`
    width: fit-content;
    position: relative;
    border-radius: ${({ theme }) => theme.spacing(4)};
    border-top-left-radius: ${({ comment, theme }) => (comment.is_customer ? 0 : theme.spacing(4))};
    border-top-right-radius: ${({ comment, theme }) => (comment.is_customer ? theme.spacing(4) : 0)};
    background-color: ${({ comment, theme, live }) =>
        comment.is_public === false
            ? lighten(0.15, theme.palette.warning.light)
            : comment.is_customer
              ? live
                  ? '#c9f0e0'
                  : theme.palette.background.paper
              : '#FAFFFD'};
    box-shadow: ${({ comment, focusedOn, theme }) =>
        `${comment.is_customer ? '3px 3px 5px' : '-1px 1px 8px'} 1px #abafd75c${
            focusedOn ? `, 0 0 0 1px ${theme.palette.text.secondary}` : ''
        };`};
    padding: ${({ theme }) => theme.spacing(3)};
    border: 1px solid
        ${({ theme, focusedOn, comment }) =>
            focusedOn ? theme.palette.text.secondary : comment.is_customer ? 'rgb(101 129 255 / 20%)' : '#C3EAD9'};
    ${({ comment }) => comment.is_customer && 'cursor: pointer;'};
    img {
        max-height: 100%;
        max-width: 100%;
    }
    overflow-x: auto;
    &:hover {
        ${({ comment, theme }) => comment.is_customer && `border: 1px solid ${theme.palette.secondary.main};`};
    }
    & table {
        border-collapse: collapse;
    }
`;

const _Backdrop = styled(Box)`
    background: linear-gradient(180deg, rgba(82, 130, 255, 0.09) 0%, rgba(124, 137, 255, 0) 100%);
    height: 52px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
`;

const _ConversationContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 0;
    flex: 1;
    padding: ${({ theme }) => theme.spacing(0, 2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
    & table {
        border-collapse: collapse;
    }
`;

export const ConversationContainer = ({ children, ...rest }: BoxProps) => (
    <_ConversationContainer {...rest}>
        <_Backdrop />
        {children}
    </_ConversationContainer>
);
