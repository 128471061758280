import { responsiveFontSizes } from '@mui/material';
import { experimental_extendTheme as extendTheme } from '@mui/material/styles';

declare module '@mui/system' {
    interface Shape {
        borderRadiusLarge: number;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        black: true;
    }
}

declare module '@mui/material/styles/createTypography' {
    interface FontStyle {
        fontTiny: string;
        fontSmall: string;
        fontMedium: string;
        fontLarge: string;
    }
}

declare module '@mui/material/styles' {
    interface Theme {
        nav: {
            default: string;
            active: string;
            text: string;
        };
        black: {
            main: string;
        };
    }

    interface CommonColors {
        selected?: string;
    }

    interface ThemeOptions {
        nav?: {
            default: string;
            active: string;
            text: string;
        };
        black: {
            main: string;
        };
    }

    interface PaletteOptions {
        black: {
            main: string;
        };
    }

    interface Components {
        Switch?: {
            backgroundColor?: string;
        };
        Input?: {
            backgroundColor?: string;
            borderColor?: string;
        };
    }

    interface TypeBackground {
        main: string;
    }
}

export const newTheme = responsiveFontSizes(
    extendTheme({
        cssVarPrefix: 'ty',
        components: {
            MuiTable: {
                styleOverrides: {
                    root: {
                        border: '1px solid #ddd',
                        borderCollapse: 'collapse',
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderRight: '1px solid #ddd',
                        borderBottom: '1px solid #ddd',
                        '&.MuiTableCell-head': {
                            fontWeight: 'bold',
                            background: 'hsl(0deg 0% 97.5%)',
                        },
                    },
                },
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        '&:before': {
                            display: 'none',
                        },
                    },
                },
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        boxShadow: 'none',
                        borderRadius: 20,
                        border: '1px solid rgb(101 129 255 / 20%)',
                    },
                },
            },
            MuiCardContent: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        padding: theme.spacing(2, 4),
                    }),
                },
            },
            MuiChip: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        margin: 0,
                        color: '#2247E8',
                        borderRadius: 30,
                        border: '1px solid #D5DDFF',
                        padding: theme.spacing(1, 2),
                    }),
                    label: {
                        padding: '0 !important',
                    },
                    filled: {
                        background: 'rgba(240, 243, 255, 0.81)',
                        backdropFilter: 'blur(2px)',
                        '&:hover': {
                            background: 'rgb(227, 232, 251)',
                        },
                    },
                    filledPrimary: {
                        backgroundColor: '#D9E0FF !important',
                        color: '#2247E8D1 !important',
                        fontWeight: 400,
                    },
                    colorWarning: ({ theme }) => ({
                        backgroundColor: `${theme.palette.warning.main} !important`,
                        color: theme.palette.common.white,
                        border: 0,
                    }),
                    colorError: ({ theme }) => ({
                        backgroundColor: `${theme.palette.error.main} !important`,
                        color: theme.palette.common.white,
                        border: 0,
                    }),
                    deleteIcon: {
                        marginLeft: 8,
                        marginRight: 0,
                        color: '#2247E8D1 !important',
                    },
                },
            },
            Switch: {
                backgroundColor: '#3E3D66',
            },
            Input: {
                backgroundColor: '#F7F8FF',
                borderColor: '#EBF2FF',
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        boxShadow: 'none',
                        textTransform: 'none',
                        borderRadius: 10,
                    },
                    contained: {
                        color: 'var(--ty-palette-common-white)',
                    },
                    sizeLarge: {
                        minWidth: 136,
                    },
                },
            },
            MuiCheckbox: {
                defaultProps: {
                    icon: <span />,
                },
                styleOverrides: {
                    root: ({ theme }) => ({
                        padding: 0,
                        borderRadius: 4,
                        width: 16,
                        height: 16,
                        backgroundColor: `${theme.palette.common.white} !important`,
                        border: '1px solid',
                        borderColor: theme.nav.default,
                        svg: {
                            fontSize: theme.typography.fontLarge,
                        },
                    }),
                    colorPrimary: ({ theme }) => ({
                        '&.Mui-checked': {
                            color: theme.palette.success.main,
                            borderColor: theme.palette.success.main,
                        },
                    }),
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: () => ({
                        '&:hover': { backgroundColor: '#dde5ff', fontWeight: 500 },
                        '&.Mui-selected': { backgroundColor: '#c4d1ff', '&:hover': { backgroundColor: '#dde5ff' } },
                    }),
                },
            },
        },
        black: {
            main: '#000',
        },
        nav: {
            default: '#252443',
            active: '#34335A',
            text: '#C8C7DD',
        },
        shape: {
            borderRadius: 10,
            borderRadiusLarge: 32,
        },
        typography: {
            fontFamily: 'Poppins, sans-serif',
            fontTiny: '.65rem',
            fontSmall: '.75rem',
            fontMedium: '0.875rem',
            fontLarge: '1.25rem',
        },
        colorSchemes: {
            light: {
                palette: {
                    black: {
                        main: '#000',
                    },
                    common: {
                        selected: '#5C7AFF',
                    },
                    success: {
                        main: '#65E2AE',
                        light: '#dcfced',
                    },
                    info: {
                        main: '#8A9BE6',
                    },
                    error: {
                        main: '#FE7CA3',
                    },
                    warning: {
                        main: '#ff9500',
                    },
                    text: {
                        primary: '#252443',
                        disabled: '#ABAFD7',
                    },
                    primary: {
                        main: '#65E2AE',
                        light: '#8DFEC8',
                        dark: '#5DCA95',
                    },
                    secondary: {
                        main: '#6D73A6',
                        light: '#6F87C5',
                    },
                    background: {
                        paper: '#FAFAFA',
                        main: '#EEF0F9',
                    },
                    divider: '#E6E9F5',
                    TableCell: {
                        border: '#ccc',
                    },
                },
            },
        },
    }),
) as ReturnType<typeof extendTheme>;
