import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { AuthSignOut } from '@tymely/api';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { userAtom } from '@tymely/atoms';

export const useUser = () => useRecoilValue(userAtom);

export const useSetUser = () => useSetRecoilState(userAtom);

export const useAuthenticate = () => {
    const setUser = useSetUser();
    const user = useUser();

    return {
        authenticate: useCallback(async () => {
            const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
            const session = cognitoUser?.getSignInUserSession();
            if (!session) return Auth.signOut();
            const userGroups: string[] = session.getAccessToken()?.payload['cognito:groups'] || [];
            const userAttributes = await Auth.userAttributes(cognitoUser);
            const userEmail = userAttributes.find((a) => a.Name === 'email')?.Value;

            setUser({
                username: cognitoUser.getUsername(),
                isAdmin: userGroups.includes('admins'),
                email: userEmail,
            });
            if (cognitoUser.getUsername()) {
                datadogRum.setUser({
                    name: cognitoUser.getUsername(),
                });
                datadogLogs.setGlobalContextProperty('username', cognitoUser.getUsername());
            }
            cognitoUser.refreshSession(session.getRefreshToken(), (err: Error) => {
                if (err) Auth.signOut();
            });
        }, [setUser]),
        user,
    };
};

export const SignOut = () => AuthSignOut().then(() => window.location.reload());
