import { ChangeEvent, useCallback, useLayoutEffect, useState, ElementType } from 'react';
import { InputBaseComponentProps } from '@mui/material';
import {
    IArgumentInteger,
    IStringValueArgument,
    IArgumentVideoUrls,
    IArgumentImageUrls,
    IArgumentUrls,
    IArgumentStrings,
    IArgumentEmailStrings,
} from '@tymely/atoms';
import { typedMemo } from '@global/types';

import { ArgumentFieldProps, StyledOutlinedInput } from './Layout';

export const InputFieldArgument = typedMemo(
    <
        T extends
            | IStringValueArgument
            | IArgumentInteger
            | IArgumentVideoUrls
            | IArgumentImageUrls
            | IArgumentUrls
            | IArgumentStrings
            | IArgumentEmailStrings,
    >(
        props: ArgumentFieldProps<T> & {
            inputComponent?: ElementType<InputBaseComponentProps>;
        },
    ) => {
        const value = typeof props.argument.value === 'string' ? props.argument.value.trim() : props.argument.value;
        const [state, setState] = useState(
            ['int', 'float'].includes(props.argument.dtype) ? value : value && String(value),
        );
        useLayoutEffect(() => {
            setState(value);
        }, [value]);

        const handleChange = useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                if (['int', 'float'].includes(props.argument.dtype)) {
                    const value = e.target.value;
                    const numericValue = Number(value);
                    if (!isNaN(numericValue)) {
                        setState(value === '' ? null : numericValue);
                    }
                } else {
                    setState(e.target.value);
                }
            },
            [props.argument],
        );

        const onUpdate = useCallback(() => {
            if (value === state) return;
            if (
                props.argument.dtype === 'list[Image]' ||
                props.argument.dtype === 'list[VideoUrl]' ||
                props.argument.dtype === 'list[Url]' ||
                props.argument.dtype === 'list[str]' ||
                props.argument.dtype === 'list[EmailStr]'
            ) {
                props.onChange?.([
                    {
                        ...props.argument,
                        value: String(state)
                            .split(',')
                            .map((v) => v.trim()),
                    },
                ]);
            } else {
                props.onChange?.([{ ...props.argument, value: state }] as T[]);
            }
        }, [value, state, props.argument, props.onChange]);

        return (
            <StyledOutlinedInput
                id={`${props.argument.extractor_cls_name.toLowerCase()}-input`}
                value={String(state ?? '')}
                edited={props.argument.is_edited}
                disabled={props.loading}
                unspecified={props.argument.is_unspecified}
                placeholder={props.argument.is_unspecified ? 'Unspecified' : ''}
                inputProps={{
                    sx: {
                        fontStyle: state === null && props.argument.is_unspecified ? 'italic' : 'normal',
                    },
                }}
                InputProps={{ inputComponent: props.inputComponent }}
                label={props.withLabel && props.argument.name}
                onChange={props.disabled ? undefined : handleChange}
                onFocus={() => {
                    if (props.setHighlightText && state) {
                        props.setHighlightText(state.toString());
                    }
                }}
                onBlur={() => {
                    onUpdate();
                    props.setHighlightText?.('');
                }}
            />
        );
    },
);
