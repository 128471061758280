import { useMemo } from 'react';
import papa, { ParseResult } from 'papaparse';
import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { MRT_TableState, type MRT_ColumnDef } from 'material-react-table';
import { type SortingState } from '@tanstack/table-core';
import { fetchReportCsvDownloadUrl, fetchReportFullState, updateReportState } from '@tymely/api';

import { useArgumentsMetadataQuery } from './argument.services';

const tandemReportCsvQueryKey = 'tandemReportCsv';
const tandemReportStateQueryKey = 'tandemReportState';

export interface TandemReportTableData {
    columns: MRT_ColumnDef<Record<string, string>>[];
    data: Record<string, string>[];
}

const fixedColumnNames = new Map([
    ['id:llm_workflow', 'Decision LLM Clustering'],
    ['id:llm_description', 'Decision LLM Clustering description'],
    ['id:ticket_id', 'Ticket ID'],
    ['id:tymely_url', 'Tymely URL'],
    ['id:datadog_logs_url', 'Datadog Logs URL'],
    ['id:ticket_original_id_from_client', 'External Ticket ID'],
    ['id:ticket_tags', 'Ticket Tags'],
    ['id:inquiry_date', 'Inquiry Date'],
    ['id:comment_id', 'Internal Comment ID'],
    ['id:comment_original_id_from_client', 'External Comment ID'],
    ['id:decision_name', 'Policy Decision'],
    ['id:decision_number', 'Workflow Number'],
    ['id:decision_explanations', 'Decision Explanations'],
    ['id:inquiry_text', 'Inquiry'],
    ['id:original_response', 'Original Response'],
    ['id:tymely_response', 'Tymely Response'],
    ['id:difference_score', 'Difference score'],
    ['id:discrepancies', 'Discrepancies'],
    ['id:suggested_response', 'Suggested response'],
]);

export const useTandemReportTableData = (reportId: string): TandemReportTableData | undefined => {
    const { data } = useQuery<string[][], AxiosError>(
        [tandemReportCsvQueryKey, reportId],
        async () => {
            const downloadUrl = await fetchReportCsvDownloadUrl(reportId);
            const parseResult = await new Promise<ParseResult<string[]>>((resolve, reject) => {
                papa.parse<string[]>(downloadUrl, {
                    delimiter: ',',
                    skipEmptyLines: true,
                    download: true,
                    complete: resolve,
                    error: reject,
                });
            });
            return parseResult.data;
        },
        {
            keepPreviousData: true,
        },
    );
    const { data: argsMetadata } = useArgumentsMetadataQuery();
    const argTitleById = useMemo(() => {
        if (!argsMetadata) {
            return undefined;
        }
        return new Map(argsMetadata.map((argMetadata) => [`arg:${argMetadata.id}`, argMetadata.title]));
    }, [argsMetadata]);

    return useMemo(() => {
        if (!data || !argTitleById) {
            return undefined;
        }
        const [columnIds, ...rows] = data;
        const columns = (columnIds ?? []).map((optionalColId, index) => {
            // For safety, in case optionalColId is an empty string:
            const columnId = optionalColId || `Column ${index + 1}`;
            return {
                id: columnId,
                accessorKey: columnId,
                header: (fixedColumnNames.get(columnId) ?? argTitleById.get(columnId)) || columnId,
            };
        });
        return {
            columns,
            data: rows.map((row) => Object.fromEntries(row.map((value, index) => [columns[index].id, value]))),
        };
    }, [data, argTitleById]);
};

export type TableState = Partial<MRT_TableState<Record<string, string>>>;

interface SortingStateWrapper {
    kind: 'sortingState';
    sortingState: SortingState;
}

interface RowsOrderWrapper {
    kind: 'rowsOrder';
    rowsOrder: string[];
}

export type SortingWrapper = SortingStateWrapper | RowsOrderWrapper;

export interface TandemReportMutableState {
    tableState?: TableState;
    editedCells?: Partial<Record<string, string>>;
    sorting?: SortingWrapper;
    clusterWorkflows?: boolean;
    scoreResponses?: boolean;
    joinArgumentsData?: boolean;
}

export interface TandemReportFullState {
    state: TandemReportMutableState;
    organization_id: number;
    intent_ids: number[];
}

const unsavedTandemReportStates = new Map<string, TandemReportMutableState>();
const debouncedRequests = new Map<string, Promise<void>>();

const createDebouncedRequest = async (reportId: string) => {
    try {
        let state, newState: TandemReportMutableState | undefined;
        do {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            state = unsavedTandemReportStates.get(reportId);
            if (!state) {
                break;
            }
            await updateReportState(reportId, state);
            newState = unsavedTandemReportStates.get(reportId);
        } while (state !== newState);
    } finally {
        unsavedTandemReportStates.delete(reportId);
        debouncedRequests.delete(reportId);
    }
};

export const useTandemReportFullStateQuery = (reportId: string) => {
    return useQuery<TandemReportFullState, AxiosError>(
        [tandemReportStateQueryKey, reportId],
        async () => {
            const fullState: TandemReportFullState = await fetchReportFullState(reportId);
            const unsavedState = unsavedTandemReportStates.get(reportId);
            return {
                ...fullState,
                ...(unsavedState && {
                    state: unsavedState,
                }),
            };
        },
        {
            refetchOnWindowFocus: 'always',
        },
    );
};

export const useTandemReportStateMutation = (reportId: string) => {
    return useMutation(
        async (newState: TandemReportMutableState) => {
            unsavedTandemReportStates.set(reportId, newState);
            const debouncedRequest = debouncedRequests.get(reportId);
            if (debouncedRequest) {
                return await debouncedRequest;
            }
            const newDebouncedRequest = createDebouncedRequest(reportId);
            debouncedRequests.set(reportId, newDebouncedRequest);
            return newDebouncedRequest;
        },
        {
            mutationKey: ['tandemReportStateQueryKey', reportId],
        },
    );
};
