import { DependencyList, useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';
import { fetchAppVersion } from '@tymely/api';
import { datadogLogs } from '@datadog/browser-logs';
import { useRecoilValue, useSetRecoilState, atom } from 'recoil';
import { IIdleTimer, useIdleTimer } from 'react-idle-timer';
import { DOCUMENT_TITLE, getEnvValue, IDLE_TIMER_TIMEOUT } from '@tymely/config';
import { getSeconds } from '@tymely/utils';
import { ZenObservable } from 'zen-observable-ts';
import { PubSub } from 'aws-amplify';

import { useSelectedComment } from './comment.services';
import { useTicket } from './ticket.services';

export const handlingTimerAtom = atom<IIdleTimer>({
    key: 'handlingTimer',
    default: undefined,
});

export const useHandlingTimer = () => useRecoilValue(handlingTimerAtom);

export const useTimeHandler = () => {
    const newHandlingTimer = useIdleTimer({ timeout: IDLE_TIMER_TIMEOUT, startOnMount: true });
    const handlingTimer = useHandlingTimer() || newHandlingTimer;
    const setHandlingTimer = useSetRecoilState(handlingTimerAtom);
    useEffect(() => {
        setHandlingTimer(handlingTimer);
    }, []);
    return handlingTimer;
};

export const useLogTicketProcess = () => {
    const ticket = useTicket();
    const comment = useSelectedComment();
    const handlingTimer = useTimeHandler();

    useEffect(() => {
        datadogLogs.setGlobalContextProperty('ticket_id', ticket.id);
    }, [ticket.id]);

    return {
        logFailed: (wfId: number, actions: string[]) => {
            datadogLogs.logger.info('Reviewer marked ticket verification failed', {
                ticket_id: ticket.id,
                organization_id: ticket?.organization_id,
                exception: `Failed actions: ${actions.join(', ')}`,
                policy_set_id: comment?.selected_intent_id,
                workflow_id: wfId,
            });
        },
        logProcessed: useCallback(
            (action: string) => {
                datadogLogs.logger.info('Ticket has been processed', {
                    action: action,
                    ticket_id: ticket.id,
                    organization_id: ticket.organization_id,
                    handling_duration: getSeconds(handlingTimer.getElapsedTime()),
                    idle_duration: getSeconds(handlingTimer.getIdleTime()),
                    active_duration: getSeconds(handlingTimer.getActiveTime()),
                });
            },
            [handlingTimer],
        ),
        logSentToQueue: (queueId: number, queueName: string) => {
            datadogLogs.logger.info(`Ticket sent to ${queueName} queue`, {
                ticket_id: ticket.id,
                organization_id: queueId,
            });
        },
    };
};

export const useAppVersionQuery = () => {
    return useQuery(['appVersion'], () => fetchAppVersion(), {
        placeholderData: { dinesh_version: '' },
        refetchInterval: 30 * 60 * 1000, // thirty minutes
        refetchIntervalInBackground: true,
    });
};

export const isMonitoringEnabled = () => {
    return getEnvValue('NX_DATADOG_APPLICATION_ID');
};

export const useDocumentTitle = (title: string, deps?: DependencyList) => {
    useEffect(() => {
        document.title = `${title} | ${DOCUMENT_TITLE}`;

        return () => {
            document.title = DOCUMENT_TITLE;
        };
    }, deps ?? []);
};

export interface IEvent {
    object_type: string;
    object_id: number | null;
    action: string | null;
    data: Record<string, unknown>;
}

export const useSubscription = (
    channel: string,
    objectTypes: string[],
    callback: (arg: IEvent) => void,
    deps: unknown[],
) => {
    useEffect(() => {
        if (channel.includes('undefined')) {
            return;
        }

        const subscription: ZenObservable.Subscription = PubSub.subscribe(channel).subscribe({
            next: (data: any) => {
                if (data.value && typeof data.value === 'object' && objectTypes.includes(data.value.object_type)) {
                    callback(data.value);
                }
            },
        });

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, deps);
};
