import { useEffect, useState, forwardRef } from 'react';
import { TextField, Box } from '@mui/material';

import { BaseModal, type ModalApi } from './BaseModal';

export type NoteModalProps = {
    title: string;
    open?: boolean;
    initialNote?: string;
    placeholder?: string;
    onSave: (note: string) => Promise<unknown>;
    onClose?: () => void;
};

export const NoteModal = forwardRef<ModalApi, NoteModalProps>((props: NoteModalProps, ref) => {
    const [note, setNote] = useState(props.initialNote || '');

    useEffect(() => {
        setNote(props.initialNote || '');
    }, [props.initialNote]);

    return (
        <BaseModal
            ref={ref}
            open={props.open || false}
            title={props.title}
            okLabel="Save Note"
            onClose={props.onClose}
            onOk={async () => {
                await props.onSave(note);
                props.onClose?.();
            }}
        >
            <Box display="flex" flexDirection="column">
                <TextField
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    variant="outlined"
                    size="small"
                    multiline
                    rows={4}
                    fullWidth
                    placeholder={props.placeholder}
                    inputProps={{ tabIndex: 0 }}
                />
            </Box>
        </BaseModal>
    );
});
