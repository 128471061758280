import { styled } from '@mui/material';

export const Blink = styled('span')`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    animation: blink 0.5s;
    animation-iteration-count: infinite;
    @keyframes blink {
        50% {
            opacity: 0;
        }
    }
`;
