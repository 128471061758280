import { memo, useMemo, ReactNode } from 'react';
import { Button, Popper, useTheme, Typography, styled, ClickAwayListener } from '@mui/material';
import { TreeView as TreeViewBase, TreeItem } from '@mui/lab';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';

export type TreeNode = {
    id: string;
    name: NonNullable<ReactNode>;
    children?: TreeNode[];
    onClick?: () => void;
};

const LeafIcon = styled(FiberManualRecordIcon)(() => ({
    fontSize: '8px !important',
}));

const renderTree = (nodes: TreeNode[]) => {
    return nodes.map((node) => {
        return (
            <TreeItem
                key={node.id}
                nodeId={String(node.id)}
                label={node.name}
                onClick={node.onClick}
                icon={!node.children?.length ? <LeafIcon /> : null}
                sx={{ pt: 0.5, pb: 0.5 }}
            >
                {Array.isArray(node.children) ? renderTree(node.children) : null}
            </TreeItem>
        );
    });
};

export const TreeView = memo((props: { tree: TreeNode[]; label: string; onOpen?: () => void }) => {
    const theme = useTheme();

    const treeNode = useMemo(() => renderTree(props.tree), [props.tree]);

    return (
        <PopupState variant="popper" popupId="tree-view-popup-popper">
            {(popupState) => (
                <>
                    <Button
                        variant="text"
                        {...bindToggle(popupState)}
                        sx={{ color: 'text.primary' }}
                        fullWidth
                        disabled={treeNode.length === 0}
                        endIcon={
                            treeNode.length > 0 && (
                                <KeyboardArrowDownIcon
                                    sx={{
                                        transform: popupState.isOpen ? 'rotate(-180deg)' : 'rotate(0g)',
                                    }}
                                />
                            )
                        }
                        onClick={(event) => {
                            bindToggle(popupState).onClick(event);
                            if (!popupState.isOpen) {
                                props.onOpen?.();
                            }
                        }}
                    >
                        <Typography
                            variant="subtitle2"
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {props.label}
                        </Typography>
                    </Button>
                    {treeNode.length > 0 && (
                        <Popper
                            {...bindPopper(popupState)}
                            placement="left"
                            sx={{
                                minWidth: theme.spacing(25),
                                maxWidth: theme.spacing(50),
                                maxHeight: theme.spacing(50),
                                backgroundColor: theme.palette.background.paper,
                                border: '1px solid',
                                borderColor: theme.palette.divider,
                                overflowY: 'auto',
                                p: 2,
                            }}
                            modifiers={[
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -50],
                                    },
                                },
                                {
                                    name: 'flip',
                                    options: {
                                        fallbackPlacements: ['top'],
                                    },
                                },
                            ]}
                        >
                            <ClickAwayListener onClickAway={() => popupState.close()}>
                                <TreeViewBase
                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                    defaultExpandIcon={<ChevronRightIcon />}
                                    sx={{
                                        width: '100%',
                                    }}
                                >
                                    {treeNode}
                                </TreeViewBase>
                            </ClickAwayListener>
                        </Popper>
                    )}
                </>
            )}
        </PopupState>
    );
});
