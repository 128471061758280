import { CSSProperties } from 'react';

export default (props: { style?: CSSProperties }) => {
    return (
        <svg
            width="10"
            height="14"
            viewBox="0 0 10 14"
            fill="none"
            style={props.style}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.80469 4.60156L5.00391 1.00156L1.20312 4.60156"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.20313 9.4L5.00391 13L8.80469 9.4"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
