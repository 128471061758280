import React from 'react';
import { groupBy } from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IPrompt, fetchPrompts, createPrompt, setLive, updatePrompt } from '@tymely/api';

export const usePromptsQuery = () => {
    const promptsQuery = useQuery(['prompts'], fetchPrompts);

    const data = React.useMemo(() => {
        const groups = groupBy(promptsQuery.data, 'name');
        return Object.keys(groups)
            .map((name) => {
                const versions = groups[name].sort((a, b) => {
                    if (a.deleted_at && !b.deleted_at) {
                        return 1;
                    }
                    if (!a.deleted_at && b.deleted_at) {
                        return -1;
                    }
                    return new Date(b.created_date).valueOf() - new Date(a.created_date).valueOf();
                });
                const current = versions.find((v) => !v.deleted_at);
                return { name, current, versions };
            })
            .sort((a, b) => {
                const promptA = a.current;
                const promptB = b.current;
                const dateA = new Date(promptA?.created_date ?? 0);
                const dateB = new Date(promptB?.created_date ?? 0);
                return dateB.getTime() - dateA.getTime();
            });
    }, [promptsQuery.data]);

    return { ...promptsQuery, data };
};

export const useCreatePromptMutation = () => {
    const client = useQueryClient();
    return useMutation((prompt: Omit<IPrompt, 'id' | 'created_date'>) => createPrompt(prompt), {
        onSuccess: (newPrompt: IPrompt) => {
            client.setQueryData(['prompts'], (prompts?: IPrompt[]) => {
                if (!prompts) {
                    return [newPrompt];
                }
                if (newPrompt.deleted_at) {
                    return prompts.concat(newPrompt);
                }
                return prompts
                    .map((p) =>
                        p.name === newPrompt.name && p.id !== newPrompt.id
                            ? { ...p, deleted_at: new Date().toISOString() }
                            : p,
                    )
                    .concat(newPrompt);
            });
        },
    });
};

export const useUpdatePromptMutation = () => {
    const client = useQueryClient();
    return useMutation(updatePrompt, {
        onSuccess: (updatedPrompt) => {
            client.setQueryData(['prompts'], (prompts?: IPrompt[]) => {
                if (!prompts) {
                    return [];
                }
                return prompts.map((prompt) => {
                    if (prompt.id !== updatedPrompt.id) {
                        return prompt;
                    }
                    return updatedPrompt;
                });
            });
        },
    });
};

export const useSetLiveMutation = () => {
    const client = useQueryClient();
    return useMutation(setLive, {
        onSuccess: (livePrompt) => {
            client.setQueryData(['prompts'], (prompts?: IPrompt[]) => {
                if (!prompts) {
                    return [];
                }
                return prompts.map((prompt) => {
                    if (prompt.name !== livePrompt.name) {
                        return prompt;
                    }
                    const deleted_at = prompt.id !== livePrompt.id ? new Date().toISOString() : undefined;
                    return { ...prompt, deleted_at };
                });
            });
        },
    });
};
