import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ReactNode } from 'react';

export const AlertDialog = (props: {
    open: boolean;
    title: string;
    description?: ReactNode;
    onClose: () => void;
    onAgree: () => void;
    onDisagree?: () => void;
}) => {
    const onOk = () => {
        props.onAgree();
        props.onClose();
    };

    const onCancel = () => {
        props.onDisagree?.();
        props.onClose();
    };

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ pb: 1 }}>{props.title}</DialogTitle>
            <DialogContent sx={{ pb: 0 }}>{props.description}</DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={onCancel}>Cancel</Button>
                <Button onClick={onOk} autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};
