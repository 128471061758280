import axios from 'axios';
import { IAppVersion, IComment, IQueueInfo, ITicket } from '@tymely/atoms';

import { AppMode } from './comment.api';

export const fetchTicket = (ticketId: number) => axios.get<ITicket>(`ticket/${ticketId}`).then((res) => res.data);

export const fetchTicketOfflineNext = (orgIds: number[], currentTicketId?: number, appMode?: AppMode) =>
    axios
        .post<ITicket | null>(
            'tickets/next/offline',
            { org_ids: orgIds },
            {
                params: {
                    current_ticket_id: currentTicketId,
                    app_mode: appMode,
                },
            },
        )
        .then((resp) => resp.data);

export const fetchTicketOnlineNext = (channels: { organization_id: number; channel: string }[]) =>
    axios.post<ITicket>('tickets/next/online', { channels }).then((resp) => resp.data);

export const fetchOnlineTicketsCount = (channels: { organization_id: number; channel: string }[]) =>
    axios.post<Promise<IQueueInfo[]>>('online/queue-info', { channels }).then((resp) => resp.data);

export const fetchAppVersion = () => axios.post<Promise<IAppVersion>>('online/app-version').then((resp) => resp.data);

export const resetTicket = (ticketId: number) =>
    axios.put<ITicket>(`ticket/${ticketId}/reset`, {}).then((res) => res.data);

export const searchTickets = (
    params: { query: string; org: number; maxResults?: number },
    signal?: AbortSignal,
): Promise<ITicket[]> =>
    axios
        .get<{ results: ITicket[] }>('tickets/search', {
            params: {
                search_term: params.query,
                org_id: params.org,
                max_results: params.maxResults,
            },
            signal,
        })
        .then((res) => res.data.results);

export const fetchCommentsSince = (ticketId: number, createdSince?: string, updatedSince?: string) => {
    return axios
        .get<IComment[]>(`ticket/${ticketId}/comments`, {
            params: {
                created_since: createdSince,
                updated_since: updatedSince || undefined,
            },
        })
        .then((res) => res.data);
};

export const postTicketMerge = (
    ticketId: ITicket['id'],
    mergeTicketIds: ITicket['id'][],
    commentId: IComment['id'],
    mode: AppMode,
) =>
    axios
        .post<ITicket>(`ticket/${ticketId}/mark_for_merge?comment_id=${commentId}&mode=${mode}`, mergeTicketIds)
        .then((resp) => resp.data);

export const getTicketsToMerge = (ticketId: ITicket['id']) =>
    axios.get<number[]>(`ticket/${ticketId}/marked_for_merge`).then((resp) => resp.data);

export const enqueueTickets = (
    ticketIds: Array<ITicket['id']>,
    channels: { organization_id: number; channel: string }[],
) => axios.post<void>('tickets/enqueue', { ticket_ids: ticketIds, channels: channels });

export const duplicateTickets = (
    ticketIds: Array<ITicket['id']>,
    channels: { organization_id: number; channel: string }[],
    untag: boolean,
    enqueueTicketsAfterDuplication: boolean,
) =>
    axios.post<void>(
        'admin/ticket/clone',
        { ticket_ids: ticketIds, channels, untag_comments: untag },
        {
            params: { enqueue: enqueueTicketsAfterDuplication },
        },
    );
