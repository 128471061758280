import { TabContext, TabList, TabPanel } from '@mui/lab';
import React from 'react';
import { styled, Tab } from '@mui/material';
import { IWaitType, useDecisionQuery, useWaitWhileLoading } from '@tymely/services';
import { useRecoilState } from 'recoil';
import { isPolicyEvaluated, isPolicyEvaluationRunning } from '@tymely/atoms';
import { useIsMutating } from 'react-query';

import useArgumentsQuery from './useArgumentsQuery';
import { PolicyExplanation } from './PolicyExplanation';
import TextArgumentSection from './TextArgumentSection';
import SystemArgumentSection from './SystemArgumentSection';
import ArgumentsProvider, { ArgumentsTabsProviderProps, ArgumentsTabsProviderRef } from './ArgumentsTabsProvider';

const _TabList = styled(TabList)`
    width: 100%;
`;

const _TabPanel = styled(TabPanel)`
    padding: ${({ theme }) => theme.spacing(2)};
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    max-height: 100%;
    padding-bottom: ${({ theme }) => theme.spacing(4)};
`;

type TabType = 'text-args' | 'sys-args' | 'explanation';

type ArgumentsTabsProps = Omit<ArgumentsTabsProviderProps, 'children' | 'onEditStatus'>;

export type ArgumentsTabsRef = {
    reset: () => void;
};

const ArgumentsTabs = React.forwardRef<ArgumentsTabsRef, ArgumentsTabsProps>(({ version, ...rest }, ref) => {
    const [tab, setTab] = React.useState<TabType>('text-args');
    const [editStatus, setEditStatus] = React.useState<string>();
    const providerRef = React.useRef<ArgumentsTabsProviderRef>();
    const argsQuery = useArgumentsQuery({ version });
    const decisionQuery = useDecisionQuery();
    const [policyEvaluated] = useRecoilState(isPolicyEvaluated);
    const [policyEvaluationRunning] = useRecoilState(isPolicyEvaluationRunning);
    const analyzing = useIsMutating('evaluatePolicy') > 0;

    React.useImperativeHandle(ref, () => ({
        reset: () => {
            setTab('text-args');
            providerRef.current?.reset();
        },
    }));

    const decision = decisionQuery.data;
    const showAnalyzedArgs = decision ? Boolean(argsQuery.data) : policyEvaluated;
    const showSystemArgumentSection = !argsQuery.isError && showAnalyzedArgs;
    const showExplanationSection = !argsQuery.isError && showAnalyzedArgs && !!decision?.wf_explanations?.length;

    const isLoading =
        argsQuery.isFetching || editStatus || analyzing || policyEvaluationRunning || decisionQuery.isLoading;

    useWaitWhileLoading(IWaitType.POLICY_EVALUATION, !!isLoading);

    const loadingStatus = isLoading ? 'Evaluating policy.' : undefined;

    const onTabChange = (_: React.SyntheticEvent, value: TabType) => setTab(value);

    return (
        <TabContext value={tab}>
            <ArgumentsProvider version={version} onEditStatus={setEditStatus} {...rest}>
                <_TabList onChange={onTabChange}>
                    {!argsQuery.isError && <Tab label="Text" value="text-args" disableRipple />}
                    {showSystemArgumentSection && <Tab label="System" value="sys-args" disableRipple />}
                    {showExplanationSection && <Tab label="Explanation" value="explanation" disableRipple />}
                </_TabList>
                {!argsQuery.isError && (
                    <_TabPanel value="text-args">
                        <TextArgumentSection loadingStatus={loadingStatus} />
                    </_TabPanel>
                )}
                {showSystemArgumentSection && (
                    <_TabPanel value="sys-args">
                        <SystemArgumentSection loadingStatus={loadingStatus} />
                    </_TabPanel>
                )}
                {showExplanationSection && (
                    <_TabPanel value="explanation">
                        <PolicyExplanation />
                    </_TabPanel>
                )}
            </ArgumentsProvider>
        </TabContext>
    );
});

export default ArgumentsTabs;
