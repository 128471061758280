export default () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.41797 18.338C6.41797 16.0464 8.2513 14.3047 10.4513 14.3047H15.1263C17.418 14.3047 19.1596 16.138 19.1596 18.338"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.5826 4.95156C17.0492 6.41823 17.0492 8.89323 15.5826 10.3599C14.1159 11.8266 11.6409 11.8266 10.1742 10.3599C8.70755 8.89323 8.70755 6.41823 10.1742 4.95156C11.6409 3.4849 14.0242 3.4849 15.5826 4.95156"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.83203 10.9974H6.41536"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.58203 12.8307L6.41536 10.9974L4.58203 9.16406"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
