import { memo, useEffect, useState } from 'react';
import { Box, TextField, MenuItem, Typography } from '@mui/material';
import { BaseModal } from '@tymely/components';
import { useAgentResponse, useTicketReport, useTicketNav, useLogTicketProcess } from '@tymely/services';
import { TicketReportCategory } from '@tymely/atoms';

export const EscalationModal = memo((props: { open: boolean; escalateResponse: string; onClose: () => void }) => {
    const [note, setNote] = useState('');
    const [response, setResponse] = useState(props.escalateResponse || '');
    const [category, setCategory] = useState<TicketReportCategory>();
    const { addEscalation } = useAgentResponse();
    const { reportCategories } = useTicketReport();
    const { logProcessed } = useLogTicketProcess();
    const { handleNext } = useTicketNav();

    useEffect(() => {
        setResponse(props.escalateResponse);
    }, [props.escalateResponse]);

    const menuElems = reportCategories.map((cat) => {
        const str = cat.toLowerCase().replace(/_/g, ' ');
        return (
            <MenuItem key={cat} value={cat}>
                {str[0].toUpperCase() + str.slice(1)}
            </MenuItem>
        );
    });

    return (
        <BaseModal
            open={props.open}
            title="Escalate"
            okLabel="Send"
            disabled={!note || !response || !category}
            maxWidth="md"
            onClose={() => {
                props.onClose();
                setCategory(undefined);
            }}
            onOk={async () => {
                if (category) {
                    logProcessed('reported');
                    await handleNext(addEscalation(note, response, category));
                    props.onClose?.();
                }
            }}
        >
            <Box display="flex" flexDirection="column">
                <TextField
                    select
                    label="Select report reason category *"
                    SelectProps={{
                        displayEmpty: true,
                        renderValue: (value) => {
                            return (
                                <Typography textTransform="capitalize" color={value ? 'text.primary' : 'text.disabled'}>
                                    {value ? (value as string).toLowerCase().replace('_', ' ') : 'Select category'}
                                </Typography>
                            );
                        },
                    }}
                    InputLabelProps={{ shrink: true }}
                    sx={{ mb: 2 }}
                    size="small"
                    value={category || ''}
                    onChange={(e) => setCategory(e.target.value as TicketReportCategory)}
                >
                    {menuElems}
                </TextField>
                <TextField
                    value={note}
                    variant="outlined"
                    size="small"
                    multiline
                    rows={5}
                    fullWidth
                    inputProps={{ tabIndex: 0 }}
                    sx={{ mb: 2 }}
                    label="Escalation Note"
                    required
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setNote(e.target.value)}
                />
                <TextField
                    value={response}
                    variant="outlined"
                    size="small"
                    multiline
                    rows={5}
                    fullWidth
                    label="Response"
                    required
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setResponse(e.target.value)}
                />
            </Box>
        </BaseModal>
    );
});
