import { Box, Button, ButtonProps, Chip, CircularProgress, styled } from '@mui/material';
import React from 'react';
import {
    AppMode,
    useAppMode,
    useCommentApproveIntent,
    useCreateTicketCrumb,
    useEvaluatePolicy,
    useFindIntent,
    useResetComment,
    useSelectedComment,
    useUser,
} from '@tymely/services';
import { IDineshTicketOperations } from '@tymely/atoms';
import { useIsMutating } from 'react-query';

import useArgumentsQuery from './useArgumentsQuery';
import { CommentChip } from '../../TicketLayout/Conversation';

const _Root = styled(Box)`
    padding: ${({ theme }) => theme.spacing(2, 2, 0)};
`;

const _Actions = styled(Box)`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const ApproveChip = styled(Chip)`
    border-radius: ${({ theme }) => theme.spacing(1)};
    font-size: 18px;
    padding: ${({ theme }) => theme.spacing(0.5, 1)};
    height: auto;
    .MuiChip-label {
        display: block;
        white-space: normal;
    }
`;

const _Buttons = styled(Box)`
    display: flex;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const _Button = (props: ButtonProps) => <Button variant="contained" size="small" {...props} />;

type IntentActionsProps = {
    version?: string;
};

const IntentActions = ({ version }: IntentActionsProps) => {
    const argsQuery = useArgumentsQuery({ version });
    const { appMode } = useAppMode();
    const comment = useSelectedComment();
    const user = useUser();

    const commentApproveIntent = useCommentApproveIntent();
    const evaluatePolicyApi = useEvaluatePolicy();
    const createTicketCrumb = useCreateTicketCrumb();
    const resetCommentApi = useResetComment(comment ?? undefined);
    const isResettingComment = useIsMutating({ mutationKey: ['resetComment', comment?.id] }) > 0;
    const intent = useFindIntent(comment?.selected_intent_id);

    const approveIntent = React.useCallback(() => {
        if (!comment) {
            return;
        }
        commentApproveIntent(comment, user?.username);
        evaluatePolicyApi.mutateAsync({ commentId: comment?.id, runAsync: true });
        createTicketCrumb(IDineshTicketOperations.USER_APPROVED_PREDICTED_INTENT, { comment_id: comment.id });
    }, [comment]);

    const resetComment = () => resetCommentApi.mutateAsync();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onDelete: React.EventHandler<any> = (e) => {
        e.stopPropagation();
        e.preventDefault();
        resetComment();
    };

    const isIntentApproved =
        appMode !== AppMode.Online || !!comment?.agent_username || !!comment?.additional_data.is_intent_approved;

    if (!intent) {
        return null;
    }

    return (
        <_Root>
            {isIntentApproved && <CommentChip label={intent.name} disabled={isResettingComment} onDelete={onDelete} />}
            {!isIntentApproved && (
                <_Actions>
                    {argsQuery.isFetching && <CircularProgress />}
                    {!argsQuery.isFetching && (
                        <>
                            <ApproveChip label={intent.name} size="medium" variant="filled" />
                            <_Buttons>
                                <_Button disabled={isResettingComment} color="success" onClick={approveIntent}>
                                    Approve Intent
                                </_Button>
                                <_Button disabled={isResettingComment} color="warning" onClick={resetComment}>
                                    Reject
                                </_Button>
                            </_Buttons>
                        </>
                    )}
                </_Actions>
            )}
        </_Root>
    );
};

export default IntentActions;
