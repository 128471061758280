import { useState, Suspense, memo, useCallback, useEffect } from 'react';
import { Select, useTheme, Box, FormControl, InputLabel, MenuItem } from '@mui/material';
import styled from 'styled-components';
import { js_beautify } from 'js-beautify';
import { IArgument, IArgumentEmailStrings, IArgumentList, IArgumentObject, IArgumentStrings } from '@tymely/atoms';
import { BaseModal } from '@tymely/components';
import { CodeEditor } from '@tymely/components/CodeEditor';

import { ArgumentFieldProps } from './Layout';

const JsonValue = memo((props: { value: string; onChange?: (value: string) => void }) => {
    const theme = useTheme();
    return (
        <Box width="100%" mb={1}>
            <Suspense>
                <CodeEditor
                    code={props.value}
                    language="json"
                    editorProps={{
                        maxHeight: theme.spacing(50),
                    }}
                    onChange={props.onChange}
                />
            </Suspense>
        </Box>
    );
});

const StyledValueRendering = styled.span<{
    isUnspecified: boolean;
}>`
    color: ${(props) => (props.isUnspecified ? 'gray' : 'black')};
    font-style: ${(props) => (props.isUnspecified ? 'italic' : 'normal')};
`;

const toJson = (value: object | string[] | null) => (value !== undefined ? js_beautify(JSON.stringify(value)) : '');

export const ObjectArgument = memo(
    (props: ArgumentFieldProps<IArgumentObject | IArgumentList | IArgumentStrings | IArgumentEmailStrings>) => {
        const [open, setOpen] = useState(false);
        const [json, setJson] = useState(toJson(props.argument.value));
        useEffect(() => {
            setJson(toJson(props.argument.value));
        }, [props.argument.value]);

        const onSubmit = useCallback(
            (json: string) => {
                try {
                    const value = JSON.parse(json);
                    props.onChange?.([{ ...props.argument, value }] as IArgument[]);
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error(e);
                }
            },
            [props.onChange],
        );

        return (
            <FormControl size="small" fullWidth sx={{ position: 'relative', justifyContent: 'center' }}>
                {props.withLabel && <InputLabel>{props.argument.name}</InputLabel>}
                <BaseModal
                    open={open}
                    okLabel="Save"
                    onOk={() => {
                        onSubmit(json);
                        setOpen(false);
                    }}
                    disabled={props.disabled}
                    onClose={() => setOpen(false)}
                    title={props.argument.title}
                    maxWidth="lg"
                >
                    <JsonValue value={json} onChange={props.disabled ? undefined : setJson} />
                </BaseModal>
                <Select
                    size="small"
                    fullWidth
                    value={json}
                    variant="outlined"
                    displayEmpty
                    renderValue={() => {
                        return (
                            <StyledValueRendering isUnspecified={props.argument.is_unspecified}>
                                {props.argument.is_unspecified ? 'Unspecified' : json}
                            </StyledValueRendering>
                        );
                    }}
                    open={false}
                    label={props.withLabel ? props.argument.name : ''}
                    onOpen={() => setOpen(true)}
                >
                    <MenuItem value={json}></MenuItem>
                </Select>
            </FormControl>
        );
    },
);
