import axios from 'axios';

export interface IPrompt {
    id: number;
    name: string;
    description: string;
    prompt?: string;
    deleted_at?: string;
    created_date: string;
    additional_data: Record<string, unknown>;
}

export const fetchPrompts = () =>
    axios
        .get<{ results: IPrompt[] }>('prompt-templates/list', { params: { limit: 1000, include_deleted: true } })
        .then((res) => res.data.results);

export const createPrompt = (prompt: Omit<IPrompt, 'id' | 'created_date'>) =>
    axios.post<IPrompt>('prompt-template', prompt).then((res) => res.data);

export const deletePrompt = (prompt: IPrompt) =>
    axios.delete<void>(`prompt-template/${prompt.id}`).then((res) => res.data);

export const updatePrompt = ({ id, created_date, ...rest }: IPrompt) =>
    axios.put<void>(`prompt-template/${id}`, rest).then(() => ({ id, created_date, ...rest }));

export const setLive = (prompt: IPrompt) =>
    axios.put<IPrompt>(`prompt-template/${prompt.id}/set-live`).then(() => ({ ...prompt, deleted_at: undefined }));

export const getPrompt = (name: string) =>
    axios.get<IPrompt>('prompt-template', { params: { name } }).then((res) => res.data);
