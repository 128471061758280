export const routes = {
    admin: '/admin',
    adminOpenApiRoutes: '/admin/openapi',
    policyEditor: '/policy-editor',
    graphPolicyEditor: '/graph-policy-editor',
    templateEditor: '/template-editor',
    argumentMetadataView: '/arguments',
    actionMetadataView: '/actions',
    ticket: '/ticket',
    onlineTicket: '/online/ticket',
    demo: '/demo',
    promptEditor: '/prompt-editor',
    ticketExchange: '/ticket-exchange',
    tandemReports: '/tandem-reports',
    organizationEditor: '/organization-editor',
};
