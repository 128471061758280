import { ForwardedRef, forwardRef } from 'react';
import { styled, IconButton as IconButtonBase, IconButtonProps as IconButtonPropsBase, darken } from '@mui/material';

export * from './SplitButton';

export type IconButtonProps = IconButtonPropsBase & { color?: 'primary' | 'secondary' | 'info' };

export const IconButton = styled(
    forwardRef((props: IconButtonProps, ref: ForwardedRef<HTMLButtonElement>) => (
        <IconButtonBase {...props} ref={ref} />
    )),
)(
    ({ theme: { palette }, color }) => `
    width: 42px;
    height: 42px;
    cursor: pointer;
    background-color: ${color ? palette[color].main : palette.common.white};
    color: ${color ? palette.common.white : palette.text.secondary};
    &.Mui-disabled {
        color: ${color ? palette.common.white : palette.text.secondary};
        background-color: ${color ? palette[color].main : palette.common.white};
    }
    &:hover {
        color: ${color ? palette.primary.light : palette.text.secondary};
        background-color: ${color ? darken(palette[color].main, 0.2) : palette.grey.A100};
        box-shadow: var(--ty-shadows-2);
    }
    &.Mui-disabled {
        opacity: 0.15;
    }
`,
);
