import { useState, useRef, memo, useCallback } from 'react';
import {
    ButtonGroup,
    Popper,
    Button,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
    useTheme,
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

export type ButtonOption = {
    name: string;
    disabled?: boolean;
    icon?: LoadingButtonProps['startIcon'];
    onClick: () => void | Promise<void>;
};

export type SplitButtonProps = LoadingButtonProps & {
    disabled?: boolean;
    options: ButtonOption[];
    className?: string;
    loading?: boolean;
};

export const SplitButton = memo((props: SplitButtonProps) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [loading, setLoading] = useState(false);

    const handleClick = useCallback(async () => {
        try {
            setLoading(true);
            await props.options[selectedIndex].onClick();
        } finally {
            setLoading(false);
        }
    }, [props.options, selectedIndex]);

    const handleMenuItemClick = (index: number) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = useCallback(() => {
        setOpen((prevOpen) => !prevOpen);
    }, []);

    const handleClose = useCallback((event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    }, []);

    const { disabled, options, className, color, ...rest } = props;

    return (
        <>
            <ButtonGroup className={className} variant="contained" ref={anchorRef} aria-label="split button">
                <LoadingButton
                    {...rest}
                    startIcon={options[selectedIndex].icon}
                    color={color}
                    variant="contained"
                    loading={loading || props.loading}
                    disabled={disabled}
                    onClick={handleClick}
                >
                    {options[selectedIndex].name}
                </LoadingButton>
                <Button size="small" color={color} disabled={disabled} onClick={handleToggle}>
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>
            <Popper
                disablePortal
                transition
                open={open}
                placement="top-start"
                anchorEl={anchorRef.current}
                sx={{ zIndex: theme.zIndex.modal }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option.name}
                                            selected={index === selectedIndex}
                                            disabled={option.disabled}
                                            onClick={() => handleMenuItemClick(index)}
                                        >
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
});
