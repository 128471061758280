import { useState, memo } from 'react';
import { Tooltip, ButtonProps } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { SxProps } from '@mui/system';

export const CopyToClipboardButton = memo(
    (props: { text: string; sx?: SxProps; size?: ButtonProps['size']; hide?: boolean }) => {
        const [copied, setCopied] = useState(false);
        const style: SxProps = {
            ...props.sx,
            fontSize: props.size === 'small' ? '1rem' : '1.5rem',
            visibility: props.hide ? 'hidden' : 'inherit',
            verticalAlign: 'middle',
        };

        const onCopy = () => {
            setCopied(true);
            setTimeout(() => setCopied(false), 3000);
        };

        return copied ? (
            <Tooltip title="Copied">
                <DoneIcon color="success" sx={style} cursor="pointer" />
            </Tooltip>
        ) : (
            <CopyToClipboard text={props.text} onCopy={onCopy}>
                <Tooltip title="Copy">
                    <ContentCopyIcon sx={style} cursor="pointer" />
                </Tooltip>
            </CopyToClipboard>
        );
    },
);
