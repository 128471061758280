export default () => {
    return (
        <svg width="38" height="35" viewBox="0 0 38 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 35C6.20914 35 8 33.433 8 31.5C8 29.567 6.20914 28 4 28C1.79086 28 0 29.567 0 31.5C0 33.433 1.79086 35 4 35Z"
                fill="#8DFEC8"
            />
            <path
                d="M19 35C20.6569 35 22 33.433 22 31.5C22 29.567 20.6569 28 19 28C17.3431 28 16 29.567 16 31.5C16 33.433 17.3431 35 19 35Z"
                fill="#8DFEC8"
            />
            <path
                d="M34.5 35C36.433 35 38 33.433 38 31.5C38 29.567 36.433 28 34.5 28C32.567 28 31 29.567 31 31.5C31 33.433 32.567 35 34.5 35Z"
                fill="#8DFEC8"
            />
            <path
                d="M35.5116 2.68268C37.1705 4.46905 38 6.95242 38 10.129V20.589C38 22.4731 36.4599 24 34.5595 24C32.659 24 31.119 22.4731 31.119 20.589V11.1068C31.119 9.57493 30.7346 8.39154 29.9658 7.55414C29.197 6.71799 28.1374 6.29929 26.7895 6.29929C25.4417 6.29929 24.3821 6.71799 23.612 7.55414C22.8432 8.39154 22.4589 9.57493 22.4589 11.1068V20.589C22.4589 22.4719 20.9188 23.9987 19.0196 23.9987C17.1204 23.9987 15.5803 22.4719 15.5803 20.589V11.1068C15.5803 9.57493 15.196 8.39154 14.4272 7.55414C13.6584 6.71799 12.5988 6.29929 11.2509 6.29929C9.90304 6.29929 8.84344 6.71799 8.07467 7.55414C7.30589 8.39154 6.9215 9.57493 6.9215 11.1068V20.5689C6.9215 22.4631 5.37257 24 3.46075 24C1.55019 24 0 22.4644 0 20.5689V0.256987H6.92024V3.23552C7.622 2.24267 8.53871 1.45542 9.67165 0.873753C10.8046 0.29334 12.0867 0.00125386 13.5168 0.00125386C15.2162 0.00125386 16.7348 0.383599 18.07 1.14954C19.4052 1.91549 20.4509 3.00737 21.2058 4.42518C21.9885 3.12144 23.0531 2.05714 24.4036 1.23353C25.7527 0.411178 27.222 0 28.8152 0C31.6209 0 33.8539 0.893811 35.5129 2.68018L35.5116 2.68268Z"
                fill="#8DFEC8"
            />
        </svg>
    );
};
