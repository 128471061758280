import React from 'react';
import { Popper, Box, styled } from '@mui/material';
import Xarrow from 'react-xarrows';

import { CommentHighlight } from './types';
import BubbleSelect from './BubbleSelect';

const _Popper = styled(Popper)`
    z-index: ${({ theme }) => theme.zIndex.modal};
    & > div {
        position: relative;
    }
`;

const useModifiers = (offset?: [number, number]) =>
    React.useMemo(
        () => [
            {
                name: 'offset',
                options: { offset },
            },
            {
                name: 'preventOverflow',
                options: {
                    mainAxis: false,
                    altAxis: false,
                },
            },
            {
                name: 'flip',
                options: {
                    fallbackPlacements: [],
                },
            },
        ],
        [],
    );

const _ArrowStart = styled(Box)`
    padding: ${({ theme }) => theme.spacing(1)};
    width: 132px;
    font-size: ${({ theme }) => theme.typography.fontSmall};
    text-align: center;
    background-color: ${({ theme }) => theme.palette.common.selected};
    border-radius: 100px;
`;

export type BubbleProps = { highlight: CommentHighlight; anchorEl: Element | null; scrollIntoView?: boolean };

const Bubble = React.memo(({ highlight, anchorEl, scrollIntoView }: BubbleProps) => {
    const id = highlight.token.toLowerCase().replace(/\s/g, '-');
    const bubble = highlight.bubble;
    const bubbleRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        bubbleRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, [scrollIntoView]);

    const modifiers = useModifiers(bubble?.offset);

    if (!bubble) {
        return null;
    }

    return (
        <Box>
            <_Popper open anchorEl={anchorEl} placement={bubble?.placement} disablePortal modifiers={modifiers}>
                <_ArrowStart id={`arrow-start-${id}`} ref={bubbleRef}>
                    {bubble.options ? <BubbleSelect title={bubble.title} options={bubble.options} /> : bubble.title}
                </_ArrowStart>
            </_Popper>
            <Xarrow
                end={id}
                start={`arrow-start-${id}`}
                startAnchor={bubble.startArrowPlacement || bubble.placement}
                endAnchor={bubble.endArrowPlacement}
                showHead={false}
                strokeWidth={1}
                color="#839AFF"
                zIndex={1}
            />
        </Box>
    );
});

export default Bubble;
