import { ReactNode, useEffect } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { Loader } from '@tymely/components';
import { useAuthenticate } from '@tymely/services';
import { getEnvValue } from '@tymely/config';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import { attachPolicy } from '@tymely/api';
import { Box } from '@mui/material';

const isLocalhost = window.location.hostname === 'localhost';

const AuthConfigure = () => {
    Amplify.configure({
        Auth: {
            mandatorySignIn: true,
            region: 'us-east-1',
            userPoolId: getEnvValue('NX_COGNITO_USER_POOL_ID'),
            identityPoolId: getEnvValue('NX_COGNITO_IDENTITY_POOL_ID'),
            userPoolWebClientId: getEnvValue('NX_COGNITO_CLIENT_ID'),
            cookieStorage: {
                domain: isLocalhost ? 'localhost' : '.tymelyinsights.com',
                secure: isLocalhost,
                expires: 3, // days
                sameSite: 'strict',
                path: '/',
            },
        },
    });

    Amplify.addPluggable(
        new AWSIoTProvider({
            aws_pubsub_region: 'us-east-1',
            aws_pubsub_endpoint: getEnvValue('NX_PUBSUB_ENDPOINT'),
        }),
    );

    Auth.currentUserCredentials().then((credentials) => {
        attachPolicy(credentials.identityId).then((d) => d);
    });
};

AuthConfigure();

export const AuthContext = ({ children }: { children: ReactNode }) => {
    const { authenticate, user: authUser } = useAuthenticate();
    useEffect(() => {
        authenticate();
    }, []);

    if (!authUser) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    height: '100vh',
                }}
            >
                <Loader />
            </Box>
        );
    }

    return <div id="app-context-children">{children}</div>;
};
