export default () => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.02462 1.47338L17.0145 8.46653C17.4052 8.66177 17.6521 9.06105 17.6521 9.49783C17.6521 9.93462 17.4052 10.3339 17.0145 10.5291L3.02462 17.5223C2.61923 17.725 2.13266 17.6715 1.78104 17.3855C1.42942 17.0995 1.27794 16.634 1.39389 16.1958L3.168 9.49783L1.39389 2.79984C1.27794 2.36166 1.42942 1.89619 1.78104 1.61017C2.13266 1.32415 2.61923 1.27063 3.02462 1.47338Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
