import React from 'react';
import { FilterOptionsState } from '@mui/material';

type SortOptionsFunction<T> = (options: T[], state: FilterOptionsState<T>) => T[];

const useSortOptions = <T>() => {
    return React.useCallback<SortOptionsFunction<T>>((options, state) => {
        const escapedInputValue = state.inputValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const matchRegex = new RegExp(escapedInputValue, 'gim');
        const exactWordRegex = new RegExp(`\\b(${escapedInputValue})\\b`, 'gim');
        const exactMatchRegex = new RegExp(`(?<!\\S)${escapedInputValue}(?!\\S)`, 'gim');
        return options
            .filter((option) => matchRegex.test(state.getOptionLabel(option)))
            .sort((option1, option2) => {
                const label1 = state.getOptionLabel(option1);
                const label2 = state.getOptionLabel(option2);
                const exactWord1 = exactWordRegex.test(label1);
                const exactWord2 = exactWordRegex.test(label2);
                if (exactWord1 || exactWord2) {
                    return exactWord1 ? -1 : 1;
                }
                const exactMatch1 = exactMatchRegex.test(label1);
                const exactMatch2 = exactMatchRegex.test(label2);
                return exactMatch1 === exactMatch2 ? 0 : exactMatch1 ? -1 : 1;
            });
    }, []);
};

export default useSortOptions;
