export default () => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 9 13" fill="none">
                <path d="M9 0V2.53704H5.91714V13H3.08286V2.53704H0V0H9Z" fill="white" />
            </svg>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginBottom: -5, marginRight: -2.5 }}
                width="13"
                height="16"
                viewBox="0 0 13 16"
                fill="none"
            >
                <path d="M6.07948 15.4111H2.97656L8.87881 0.585938H12.0034L6.07948 15.4111Z" fill="white" />
                <path d="M5.833 6.00102L4.55265 9.1232L0.429688 0.585938H3.55425L5.833 6.00102Z" fill="white" />
            </svg>
        </div>
    );
};
