import { memo, ComponentProps, KeyboardEvent, KeyboardEventHandler, ReactNode } from 'react';
import { alpha, Box, OutlinedInputProps, OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled(Box)(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
}));

const IconWrapper = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInput = styled(({ outlined, ...props }: OutlinedInputProps & { outlined?: boolean }) => (
    <OutlinedInput {...props} />
))(({ outlined }) => ({
    color: 'inherit',
    '& fieldset': {
        display: outlined ? 'block' : 'none',
    },
}));

type IconInputProps = Omit<ComponentProps<typeof Box>, 'onSubmit'> & {
    icon: ReactNode;
    input?: OutlinedInputProps & { outlined?: boolean };
    clearOnSubmit?: boolean;
    onSubmit?: (value: string) => void;
};
export const IconInput = memo(({ icon, input, onSubmit, clearOnSubmit = true, ...root }: IconInputProps) => {
    const handleOnKeyUp: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
        e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        if (onSubmit && e.key === 'Enter') {
            onSubmit(e.currentTarget.value);
            if (clearOnSubmit) {
                e.currentTarget.value = '';
            }
            return;
        }

        input?.onKeyUp?.(e);
    };

    return (
        <Root {...root} id="icon-input">
            <IconWrapper className="iconWrapper">{icon}</IconWrapper>
            <StyledInput {...input} onKeyUp={handleOnKeyUp} />
        </Root>
    );
});
