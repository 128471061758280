import { SyntheticEvent, useCallback, useMemo } from 'react';
import { Autocomplete, Box, Skeleton, styled, TextField, TextFieldVariants } from '@mui/material';

import useSortOptions from './useSortOptions';

type QueryBasedOption = { id: number; name: string };

type QueryBasedSelectorProps<T extends QueryBasedOption> = {
    label?: string;
    loading?: boolean;
    error?: boolean;
    data: T[];
    selectObj: (obj: T) => void;
    selectedObjId?: T['id'];
    getOptionLabel?: (obj: T) => string;
    filter?: (obj: T) => boolean;
    sort?: (obj1: T, obj2: T) => number;
    disabled?: boolean;
    minWidth?: string;
    className?: string;
    color?: string;
    variant?: TextFieldVariants;
};

const _Root = styled(Box)`
    height: ${({ theme }) => theme.spacing(4)};
    width: ${({ theme }) => theme.spacing(35)};
`;

const _SelectorSkeleton = styled(Skeleton)`
    width: 100%;
    height: ${({ theme }) => theme.spacing(5)};
    border-radius: ${({ theme }) => theme.spacing(0.3)};
    margin-bottom: -8px;
`;

const _TextField = styled(TextField)<{ inputColor?: string }>`
    ${({ inputColor }) =>
        inputColor &&
        `.MuiInputBase-input {
    color: ${inputColor};
    }`};
`;

export function QueryBasedSelector<T extends QueryBasedOption = QueryBasedOption>(props: QueryBasedSelectorProps<T>) {
    const getOptionLabel = props.getOptionLabel ?? ((option) => option.name);
    const data = useMemo(
        () => props.data?.filter((obj) => !props.filter || props.filter(obj)) ?? [],
        [props.data, props.filter],
    );

    const selectedObj = useMemo(() => {
        return data?.find((org) => org.id === props.selectedObjId) ?? null;
    }, [data, props.selectedObjId]);

    const onChange = useCallback(
        (_: SyntheticEvent, newValue: T | null) => {
            if (newValue) {
                props.selectObj(newValue);
            }
        },
        [props.selectObj],
    );

    const sortOptions = useSortOptions<T>();

    return (
        <_Root className={props.className}>
            {props.loading && <_SelectorSkeleton variant="rectangular" animation="wave" height="100%" />}
            {!props.loading && (
                <Autocomplete
                    fullWidth
                    autoHighlight
                    disablePortal
                    size="small"
                    disabled={props.disabled ?? props.error}
                    value={selectedObj}
                    options={data}
                    filterOptions={sortOptions}
                    renderInput={(params) => {
                        return (
                            <_TextField
                                {...params}
                                variant={props.variant ?? 'standard'}
                                color="secondary"
                                placeholder={props.error ? 'Error' : props.label}
                                inputProps={params.inputProps}
                                inputColor={props.color}
                            />
                        );
                    }}
                    getOptionLabel={getOptionLabel}
                    onChange={onChange}
                />
            )}
        </_Root>
    );
}
