import uniqBy from 'lodash/uniqBy';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
    fetchWfIntentPolicy,
    ISchemaError,
    SchemaError,
    NonEmptyItemOverwrite,
    upsertWfIntentPolicy,
    changeWFStatus,
    FetchWfIntentPolicyArgs,
    invalidateWfIntentPolicyCache,
} from '@tymely/api';
import {
    conditionTitles,
    IPolicySet,
    IWfPolicy,
    IOrganization,
    IUiPolicy,
    IWorkflow,
    IPolicyCondition,
    IUiWorkflow,
    IUiAction,
} from '@tymely/atoms';
import { UseQueryOptions } from 'react-query/types/react/types';
import { AxiosError } from 'axios';
import { omit } from 'lodash';

import { useOrganizationQuery } from './organization.services';

const INTENT_POLICY_QUERY_KEY = 'intentPolicy';

export type UseFetchWfPolicyQueryArgs = UseQueryOptions<IWfPolicy, AxiosError> & {
    orgId: IOrganization['id'];
    intentId: IPolicySet['id'];
    asSeenAt?: string;
};

const conditionalStrip = <T extends Record<string, unknown>>(data: T) => {
    return omit(data, 'deleted_at', 'id') as T;
};

const stripWorkflows = (workflows: IUiWorkflow[]) =>
    workflows.map<IUiWorkflow>((wf) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const conditions = wf.conditions.map<IPolicyCondition>(conditionalStrip);
        const actions = wf.actions.map<IUiAction>(conditionalStrip);
        return conditionalStrip<IUiWorkflow>({ ...wf, conditions, actions });
    });

export const useFetchWfIntentPolicyQuery = ({ orgId, intentId, asSeenAt, ...options }: UseFetchWfPolicyQueryArgs) => {
    const organizationQuery = useOrganizationQuery(orgId);
    const orgPolicySetId = organizationQuery.data?.org_policy_set_id;

    const params: FetchWfIntentPolicyArgs = { orgPolicySetId: orgPolicySetId ?? 0, intentId };
    if (asSeenAt) {
        params.asSeenAt = asSeenAt;
    }

    let enabled = Boolean(orgPolicySetId && intentId);
    if (typeof options.enabled === 'boolean') {
        enabled = enabled && options.enabled;
    }

    return useQuery<IWfPolicy, AxiosError>(
        [INTENT_POLICY_QUERY_KEY, orgId, intentId, asSeenAt],
        () =>
            fetchWfIntentPolicy(params).then((policy) => {
                return { orgId: orgPolicySetId ?? 0, intentId, workflows: policy.workflows };
            }),
        { ...options, enabled },
    );
};

const isNumeric = (value: string | number): boolean => {
    return Number(value) === 0 || !!Number(value);
};

const formatUpsertPolicySchemaErrors = (error: ISchemaError, policy: IUiPolicy): string[] => {
    const errors = uniqBy(error.detail, (err) => err.loc.join());
    return (
        errors
            .filter(
                (err) => !(err.loc[3] === 'conditions' && err.loc[5] === 'id' && err.type === 'value_error.missing'),
            )
            // The first filter handles server quirks, don't remove it and don't combine it with any filter below!
            .map((err) => {
                if (err.loc[1] === 'workflows' && err.loc.length > 2 && isNumeric(err.loc[2])) {
                    const workflow = policy.workflows[err.loc[2] as number];
                    const workflowId = workflow.id || `"${workflow.title}"`;

                    if (err.type === 'value_error' && err.loc[3] === 'conditions' && isNumeric(err.loc[4])) {
                        const condition = workflow.conditions[err.loc[4] as number];
                        return `Workflow ${workflowId}: bad condition "${conditionTitles[condition.predicate]}" for "${
                            condition.argument_metadata.title
                        }": ${err.msg}`;
                    }

                    return `Workflow ${workflowId}: ${err.msg}`;
                }

                return `${err.loc.join('->')}: ${err.msg}`;
            })
    );
};

export const useUpsertWfIntentPolicyMutation = (props: {
    onSuccess?: (policy: IWfPolicy) => void;
    onError?: (error: string[]) => void;
}) => {
    const queryClient = useQueryClient();

    const { onSuccess } = props;
    return useMutation(
        [INTENT_POLICY_QUERY_KEY],
        ({ policy, mode }: { policy: IUiPolicy; mode: 'overwrite' | 'append' }) => {
            const workflows = stripWorkflows(policy.workflows);
            return upsertWfIntentPolicy({ ...policy, workflows }, mode);
        },
        {
            onSuccess: async (data, { policy }) => {
                queryClient.setQueryData([INTENT_POLICY_QUERY_KEY, policy.orgId, policy.intentId], () => data);
                return onSuccess?.({ ...policy, ...data });
            },
            onError: (error, { policy }) => {
                if (error instanceof SchemaError) {
                    props.onError?.(formatUpsertPolicySchemaErrors(error.detail, policy));
                } else if (error instanceof NonEmptyItemOverwrite) {
                    props.onError?.([error.message]);
                } else {
                    props.onError?.([
                        `Failed saving policy for intent (id=${policy.intentId}, org_id=${policy.orgId}): ${
                            (error as any).message
                        }`,
                    ]);
                }
            },
        },
    );
};

export const useInvalidateWfIntentPolicyCacheMutation = (props: {
    onSuccess?: (policy: IWfPolicy) => void;
    onError?: (error: string[]) => void;
}) => {
    const queryClient = useQueryClient();

    const { onSuccess } = props;
    return useMutation(
        [INTENT_POLICY_QUERY_KEY],
        ({ policy }: { policy: Pick<IUiPolicy, 'orgId' | 'intentId'> }) => invalidateWfIntentPolicyCache(policy),
        {
            onSuccess: async (data, { policy }) => {
                queryClient.setQueryData([INTENT_POLICY_QUERY_KEY, policy.orgId, policy.intentId], () => data);
                return onSuccess?.({ ...policy, ...data });
            },
            onError: (error, { policy }) => {
                if (error instanceof NonEmptyItemOverwrite) {
                    props.onError?.([error.message]);
                } else {
                    props.onError?.([
                        `Failed invaliding policy cache for intent (id=${policy.intentId}, org_id=${policy.orgId}): ${
                            (error as any).message
                        }`,
                    ]);
                }
            },
        },
    );
};

export const useUpdateWorkflow = (onSuccess?: (intent: IWorkflow) => void) => {
    const changeStatus = useMutation((args: [IWorkflow['id'], IWorkflow['status']]) => changeWFStatus(...args), {
        onSuccess,
    });

    return {
        changeStatus,
    };
};
