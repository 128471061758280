import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { TimelineOppositeContent } from '@mui/lab';
import { styled, Typography } from '@mui/material';

const StyledTimelineConnector = styled(TimelineConnector)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
}));

interface TimelineListProps {
    id: string;
    items: string[];
}

export const TimelineList = ({ items, id }: TimelineListProps) => {
    const numItems = Object.entries(items).length;

    return (
        <Timeline id={`${id}-timeline-list`} sx={{ m: 0, p: 0 }}>
            {Object.entries(items).map(([key, item], index) => (
                <TimelineItem key={`timeline-item-${key}`} sx={{ minHeight: '1em' }}>
                    <TimelineOppositeContent sx={{ display: 'none' }}></TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot variant="outlined" color="primary" />
                        {index < numItems - 1 && <StyledTimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant="subtitle2">{item}</Typography>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );
};
