import React from 'react';
import { Box, Card, CardContent, Chip, styled, Typography } from '@mui/material';
import { AppMode, useAppMode, useDecisionQuery, useSelectedComment } from '@tymely/services';
import { IAction } from '@tymely/atoms';
import { TimelineList } from '@tymely/components';

const _Root = styled(Card)`
    overflow: unset;
    margin: ${({ theme }) => theme.spacing(1, 1, 0, 1)};
    padding: ${({ theme }) => theme.spacing(1)};
    background: ${({ theme }) => theme.palette.secondary.main};
    .MuiTypography-root {
        color: ${({ theme }) => theme.palette.background.paper};
    }
    .MuiSvgIcon-root {
        color: ${({ theme }) => theme.palette.background.paper};
    }
`;

const _CardContent = styled(CardContent)`
    padding: ${({ theme }) => theme.spacing(0, 2)};
    padding-bottom: 0 !important;
`;

const _InnerContent = styled(Box)`
    margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const _Label = styled(Typography)`
    margin: ${({ theme }) => theme.spacing(1, 0)};
`;

const _Title = styled(Box)`
    padding: ${({ theme }) => theme.spacing(0, 0.5)};
`;

const _ChipContainer = styled(Box)`
    margin-top: ${({ theme }) => theme.spacing(2)};
    margin-bottom: ${({ theme }) => theme.spacing(1)};
    display: flex;
    justify-items: center;
`;

const _DecisionLabel = styled(Typography)`
    display: inline;
    margin-right: ${({ theme }) => theme.spacing(2)};
`;

const DecisionChip = styled(Chip, { shouldForwardProp: (prop: string) => !['isLive'].includes(prop) })<{
    isLive: boolean;
}>`
    margin-top: ${({ theme }) => theme.spacing(0.5)};
    font-size: 0.8rem;
    background-color: ${({ isLive, theme }) => (isLive ? theme.palette.primary.main : theme.palette.warning.main)};
    border: none;
    color: #fff;
`;

const prepareActions = (actions: IAction[]) => {
    return actions.sort((a, b) => a.order - b.order).map((action) => action.title);
};

type DecisionProps = {
    version?: string;
};

const Decision = ({ version }: DecisionProps) => {
    const decisionQuery = useDecisionQuery(!version);
    const { appMode } = useAppMode();
    const comment = useSelectedComment();
    const decision = decisionQuery.data;

    const titles = React.useMemo(() => {
        if (!decision?.actions) {
            return [];
        }
        return prepareActions(decision?.actions);
    }, [decision]);

    const isIntentApproved =
        appMode !== AppMode.Online || !!comment?.agent_username || !!comment?.additional_data.is_intent_approved;

    if (!decision || !isIntentApproved) {
        return null;
    }

    return (
        <_Root>
            <_CardContent>
                <_InnerContent>
                    <_ChipContainer>
                        <_DecisionLabel variant="h6">Decision </_DecisionLabel>
                        <DecisionChip
                            label={decision.status}
                            size="small"
                            isLive={decision.status === 'LIVE'}
                            variant="filled"
                        />
                    </_ChipContainer>
                    <_Label>
                        Workflow {decision.workflow_id}:
                        {decision.title && <_Title component="span">{decision.title}</_Title>}
                        {!decision.title && 'No decision'}
                    </_Label>
                    {!!titles.length && <TimelineList id="comment-actions" items={titles} />}
                </_InnerContent>
            </_CardContent>
        </_Root>
    );
};

export default Decision;
