import { useEffect, useMemo, useState } from 'react';
import Modal from '@mui/base/Modal';
import { ITicket } from '@tymely/atoms';
import { groupComments } from '@tymely/services';
import CloseIcon from '@tymely/components/Icons/Close';
import { styled, Backdrop, Box, IconButton } from '@mui/material';

import { TicketHeader } from '../TicketLayout/Main';
import { ConversationContainer, Discussion } from '../TicketLayout/Conversation';

const StyledModal = styled(Modal)`
    position: fixed;
    z-index: 1300;
    inset: 0;
    display: flex;
`;

const ModalContent = styled(Box)(
    ({ theme }) => `
    display: flex;
    flex-direction: column;
    position: absolute;
    transform: translateX(-50%);
    margin-left: 50%;
    top: ${theme.spacing(2)};
    height: 90vh;
    top: 5vh;
    width: 60%;
    background-color: ${theme.palette.background.main};
    padding-bottom: 0;
    border-radius: 20px;
`,
);

export const TicketModal = (props: { ticket: ITicket; selectedCommentId: number }) => {
    const [open, setOpen] = useState(true);
    const comments = useMemo(() => {
        return groupComments(props.ticket.comments || []);
    }, [props.ticket.comments]);

    useEffect(() => {
        setOpen(true);
    }, [props.selectedCommentId]);

    return (
        <StyledModal open={open} slots={{ backdrop: Backdrop }} onClose={() => setOpen(false)}>
            <ModalContent>
                <TicketHeader ticket={props.ticket} />
                <ConversationContainer>
                    <Discussion
                        ticket={props.ticket}
                        comments={comments}
                        commentIdToFocusOn={props.selectedCommentId}
                    />
                </ConversationContainer>
                <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={() => setOpen(false)}>
                    <CloseIcon />
                </IconButton>
            </ModalContent>
        </StyledModal>
    );
};
